import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import moment from 'moment'
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

class ItemMovementDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: '',
            isLoading: false,
            tabValue: 0,
            relatedItem: [],
        }
    }
    
    componentDidMount() {
        let urlQuery = queryString.parse(window.location.search);
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                        data
                    })
                }
            })
        }
    }
    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('item_movements/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
               
                    var json_data = data.result.item;
                    var result = [];

                    for (var i in json_data) {
                        result[i] = json_data[i];
                      }

                this.setState({
                    isLoading: false,
                    relatedItem: result,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
                
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }

    itemDetailPage = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>movement type</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.movement_type && this.state.data.movement_type !== null ? this.state.data.movement_type :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>status</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.status && this.state.data.status !== null ? <>{this.state.data.status == true? <Typography style={{color:'#28a745', fontWeight:'900'}}>Active</Typography>: <Typography style={{color:'#28a745', fontWeight:'900'}}>In Active</Typography>} </>:'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>reason</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.reason && this.state.data.reason !== null ? this.state.data.reason :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>new stock</Typography>
                            </Box>   
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.new_stock && this.state.data.new_stock !== null && this.state.data.new_stock > 0 ? this.state.data.new_stock :'-' } </Typography>
                            </Box>  
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>new price</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.new_price && this.state.data.new_price !== null && this.state.data.new_price > 0 ? this.state.data.new_price :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>stock movement</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.stock_movement && this.state.data.stock_movement !== null ?  this.state.data.stock_movement:'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>price movement</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.price_movement && this.state.data.price_movement !== null ? this.state.data.price_movement :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>movement date</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.movement_date && this.state.data.movement_date !== null ? moment(this.state.data.movement_date).format('DD-MMM-YYYY') :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Warehouse</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.warehouse_id && this.state.data.warehouse_id !== null &&  this.state.data.warehouse_id > 0 && this.state.data.warehouse ? this.state.data.warehouse.name :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Opening Stock</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.opening_stock && this.state.data.opening_stock !== null &&  this.state.data.opening_stock > 0 ? this.state.data.opening_stock :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Stock On Hand</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.opening_stock && this.state.data.stock_on_hand !== null &&  this.state.data.stock_on_hand > 0 ? this.state.data.stock_on_hand :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={4}>
                    <Typography variant="h5">Images</Typography>
                    <Box mt={5}>{this.importedImglist()}</Box>
                </Grid> */}
            </Grid>
            
        </Box>
        
        </>
    }

    RelatedItems = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12}>
                <Grid item xs={8}>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>SKU</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.sku && this.state.relatedItem.sku !== null ? this.state.relatedItem.sku :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Item Type</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.item_type && this.state.relatedItem.item_type !== null ? this.state.relatedItem.item_type :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Brand</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.brand && this.state.relatedItem.brand !== null ? this.state.relatedItem.brand :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Weight</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.weight && this.state.relatedItem.weight !== null && this.state.relatedItem.weight > 0 ? this.state.relatedItem.weight :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Dimensions</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.dimensions && this.state.relatedItem.dimensions !== null && this.state.relatedItem.dimensions > 0 ? this.state.relatedItem.dimensions :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Inbound Date</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.inbound_date && this.state.relatedItem.inbound_date !== null ?  moment(this.state.relatedItem.inbound_date).format('DD-MMM-YYYY'):'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Cost Price</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.cost_prices && this.state.relatedItem.cost_prices !== null &&  this.state.relatedItem.cost_prices > 0 ? this.state.relatedItem.cost_prices :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Selling Price</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.selling_prices && this.state.relatedItem.selling_prices !== null &&  this.state.relatedItem.selling_prices > 0 ? this.state.relatedItem.selling_prices :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Warehouse</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.warehouse_id && this.state.relatedItem.warehouse_id !== null &&  this.state.relatedItem.warehouse_id > 0 && this.state.relatedItem.warehouse ? this.state.relatedItem.warehouse.name :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Opening Stock</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.opening_stock && this.state.relatedItem.opening_stock !== null &&  this.state.relatedItem.opening_stock > 0 ? this.state.relatedItem.opening_stock :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Stock On Hand</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.relatedItem.opening_stock && this.state.relatedItem.stock_on_hand !== null &&  this.state.relatedItem.stock_on_hand > 0 ? this.state.relatedItem.stock_on_hand :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </Box>
        
        </>
    }

    // importedImglist = () => {
    //     return <>
    //     {this.state.data && this.state.data.item_images.length > 0 ?
        
    //     this.state.data.item_images.map((img)=>{
            
    //        return<img src={img.image_url} width="100px" height="auto" style={{margin:"5px"}}/>
    //     }) : "" }

    //     </>
    // }
    getDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="Movement Details" {...a11yProps(0)} />
          <Tab label="Related Items" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={this.state.tabValue} index={0}>
        {this.itemDetailPage()}
      </TabPanel>
      <TabPanel value={this.state.tabValue} index={1}>
        {this.RelatedItems()}
      </TabPanel>
      {/* <TabPanel value={this.state.tabValue} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
        </>
    }
    render() {
        return <Box><StickyPageHeader>
            <Container>
                <BreadCrumb />
                <h1 className="page-title">Movement Details</h1>
                {/* <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} />
                <IconButton className="btn-theme btn-theme-1" label="Edit " icon="edit" onClick={() => this.props.history.push('./edit?id='+this.state.dataId)} />
                <IconButton className="btn-theme btn-theme-1" label="Adjust" icon="edit" onClick={() => this.props.history.push('./movement-form?id='+this.state.dataId)} /> */}
            </Container>
            </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Box mt={2} >
                   <Typography variant="h5"> {this.state.data.name}</Typography>
                   {this.getDetails()}
                  </Box>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>   
            </Container>
      </Box>

    }
}

export default ItemMovementDetail;