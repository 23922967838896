import React, { Component } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

import * as XLSX from "xlsx";
import apiUtil from "../../../api/apiUtil";

class ImportItemDialog extends Component {
  constructor(props) {
    super(props);
    
    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
      file: ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    });
    this.props.onClose();
  }

  filePathset = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    this.setState({file: file});
    // if (file.type !== "application/vnd.ms-excel" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    //   apiUtil.toast("Only excel files allowed!", "error");
    //   this.setState({file: ""});
    // } else {
    //   this.setState({file: file});
    // }
  }

  readFile = () => {
    let f = this.state.file;
    if (f === "") {
      apiUtil.toast("No file is selected yet.", "error");
      return false;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = XLSX.read(bstr, { type: "binary"});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      if (data.length <= 0) {
        apiUtil.toast('Empty data!', 'error');
        return false;
      }

      let res = [];
      data.map(function(v,k){
        res.push(v.join("|||"));
      });

      let jsonData = this.convertToJson(res);
      this.props.onImport(jsonData);
      this.setState({modal: false});
    };
    reader.readAsBinaryString(f);
  }

  convertToJson = csv => {
    let lines = csv;
    let result = [];
    let headers = lines[0].split("|||");

    for (let i = 1; i < lines.length; i++) {
      let obj = {
        id: null,
        delete: null
      };
      let currentline = lines[i].split("|||");

      for (let j = 0; j < headers.length; j++) {
        let key;

        switch(headers[j]) {
          case "Part Number":
            key = "part_no";
            break;
          case "Description":
            key = "description";
            break;
          case "Quantity":
            key = "quantity";
            break;
          case "Serial Number":
            key = "serial_no";
            break;
          case "Lot Number":
            key = "lot_no";
            break;
          case "Width (cm)":
            key = "width";
            break;
          case "Length (cm)":
            key = "length";
            break;
          case "Height (cm)":
            key = "height";
            break;
          case "Expiry Date (dd/mm/yyyy)":
            key = "expiry_date";
            break;
          case "Location":
            key = "location";
            break;
          case "Remarks":
            key = "remarks";
            break;
          default:
            key = "";
        }

        if (key !== "") {
          obj[key] = currentline[j];
        }
      }
      result.push(obj);
    }

    
    return result;
  }

  render(){
    return(
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="m">
        <ModalBody>
          <p><strong>To import Outbound Order from excel:</strong></p>
          <ol>
            <li>Download import template by clicking the link below</li>
            <li>Upload the sheet and click "Import Outbound Order" button.</li>
          </ol>
          <input
            type="file"
            id="file"
            ref="fileUploader"
            accept=".xls,.xlsx"
            onChange={this.filePathset.bind(this)}
          />
          <br />
          <a href="/wms-sales-order-excel-template.xls" download>Download Template</a>
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={() => this.readFile()}>
            Import Outbound Orders
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ImportItemDialog;