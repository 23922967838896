import React from 'react';

import {
    Box,
    Popper,
    MenuItem,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList
} from '@material-ui/core';

import CustomButton from '../CustomFields/CustomButton.jsx';

const DropdownMenu = props => {
    const { text, leftIcon, rightIcon, color, items, onClick, variant, className, isShouldEnable } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      setOpen(prevOpen => !prevOpen);
    };
  
    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }


    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);
    

    const setMenuItems = () => {
      if(items && items.length > 0){
        return items.map((item, i) => {
          return <MenuItem 
            key={i}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if(onClick){
                onClick(item);
                setOpen(false);
              }
            }}
          >
            {item.text}
          </MenuItem>
        });
      }
    };


    return (
        <Box ref={anchorRef} className="w-100">
            <CustomButton
              color={(color ? color : 'primary')}
              variant={(variant ? variant : 'contained')}
              onClick={handleToggle}
              className={className}
              fullWidth={true}
            >
              <Box position={'absolute'} width={'100%'}>
                {leftIcon && <span style={{ position: 'absolute', left: '15px' }}>{leftIcon}</span>}
                <span>{text}</span>
                {rightIcon && <span style={{ position: 'absolute', right: '15px' }}>{rightIcon}</span>}
              </Box>
            </CustomButton>
            <Popper open={(isShouldEnable === false) ? isShouldEnable : open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: '9999', width: (anchorRef.current) ? anchorRef.current.offsetWidth + 'px' : 'auto' }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                    {setMenuItems()}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
  }

  export default DropdownMenu;