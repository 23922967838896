import React, { Component } from "react";

import { Box, Container } from '@material-ui/core';
import { Row, Label, Form, FormGroup, Col } from "reactstrap";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";

import apiUtil from '../../api/apiUtil';
import reportApi from "../../api/Report";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MonthlyReportTable from "./Partial/MonthlyReportTable";

class MonthlyReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDate: new Date(),
      isLoading: false,
      reportData: null
    };
  }

  componentDidMount = () => {
    document.title = "Monthly Summary Report | WMS";
    window.scrollTo(0, 0);
  }

  changeDate = date => {
    this.setState({
      displayDate: date
    });
  }

  generateReport = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true
    });

    let selectedDate = this.state.displayDate;
    let startDate = moment(selectedDate).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');

    await reportApi.getMonthly({
      start_date: startDate,
      end_date: endDate
    }, this.state.filter).then(data => {
      if (data.errorMessage !== "") {
        apiUtil.toast(data.errorMessage, 'error');
        this.setState({
          isLoading: false,
        });
        return false;
      }

      this.setState({
        isLoading: false,
        reportData: data.data.data
      });
    }).catch(error => console.log(error));
  }

  render () {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
          <BreadCrumb />
          <h1 className="page-title">Monthly Summary Report</h1>
          </Container>
        </StickyPageHeader>
        <Container>
        <Row className="mt-3">
          <Col md={{ size: 8, offset: 2 }}>
            <Form onSubmit={this.generateReport}>
              <FormGroup className="mb-4">
                <Label className="control-label">
                  Month
                </Label>

                <DatePicker className="form-control" selected={this.state.displayDate} onChange={date => this.changeDate(date)} dateFormat="MM/yyyy" showMonthYearPicker />
              </FormGroup>

              <FormGroup className="mb-4">
                <Label className="control-label">{' '}</Label>
                <div className="form-input">
                  <ProgressButton
                  type={'submit'}
                  color={'secondary'}
                  isLoading={this.state.isLoading}>
                    <Box pl={2} pr={2}>Generate Report</Box>
                  </ProgressButton>
                </div>
              </FormGroup>
            </Form>
          </Col>
        </Row>

        {this.state.reportData !== null &&
          <MonthlyReportTable reportData={this.state.reportData} />
        }
        </Container>
      </Box>
    );
  }
}

export default MonthlyReport;