import React, { Component } from "react";

import { 
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import MUIDataTable from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import Table from '../../components/Table/Table.jsx';
import Select from 'react-select';
import { Row, Container, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle,DropdownMenu, DropdownItem, Input, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

import moment from "moment";

class ItemMpvement extends Component {
    constructor(porps) {
        super(porps)
        this.state = {
            isLoading: false,
            page: 0,
            limit: 20,
            total: 0,
            data: [],
            openDialog: false,
            openDialogItem: null,
        }
    }

    componentDidMount = () => {
        this.callReadApi()
    }

    
    callReadApi = (search = '') => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
          isLoading: true
        }, () => {
          let param = {
            page: this.state.page,
            take: this.state.limit,
            sort: 'latest',
            search: search,
          };
    
          wmsApi.read('item_movements', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  data: (data.result && data.result.length > 0) ? data.result : [],
                  total: data.total,
                  isLoading: false,
                });
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
        });
      }

    callDeleteApi = (id, callback = null) => {
      const  accessToken = localStorage.getItem("access_token");
  
      this.setState({
        isLoading: true
      }, () => {
        let param = {
        };
  
        wmsApi.delete('item_movements/' + id, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
        });
      });
    }
    /* DIALOG */
    customDialog = () => {
      return <CustomDialog 
        open={this.state.openDialog}
        title={'Delete'}
        onClose={() => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          });
        }}
        onOk={() => {
          let row = this.state.openDialogItem;
          this.callDeleteApi(row, () => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
            }, () => {
              this.callReadApi();
              apiUtil.toast('Successfully Deleted', 'success');
            });
          });
        }}
      >
        <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
    }

    deleteData = id => {
      this.setState({
        openDialog: true,
        openDialogItem: id
      });
    }

      /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      className={'custom-table-list'}
      isLoading={this.state.isLoading}
      grouping={false}
      selection={false}
      page={this.state.page}
      total={this.state.total}
      limit={this.state.limit}
      onPageChange={(page,limit) => {
        this.setState({
          limit:limit,
          page:page
        }, () => {
          this.callReadApi();
        });
        }}
      data={this.state.data}
      onSelectionChange={(selectedRows) => {
        // console.log("selectedRows", selectedRows);
      }}
      // Row={this.customRow}
      columns={[
        
        { 
            title: "Item ", 
            field: "item",
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                        {row.item && row.item.name ? <a href={'./item-movement-detail?id=' + row.id}>{row.item.name}</a>: <span style={{color:"red"}}>deleted-item</span>}
                    </Box>;
                } else {
                    return row;
                }
            }
        },
        { 
            title: "Movement Type", 
            field: "movement_type",
        },  
        { 
            title: "User", 
            field: "User",
            render: (row, type) => {
              if(type === 'row'){
                  return <Box>
                  {row.user ? row.user.first_name  : ''}
                </Box>;
              } else {
                  return row;
              }
          }
        },
        { 
            title: "New", 
            field: "New",
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                    {row.movement_type == "stock" ? row.new_stock  : row.new_price}
                  </Box>;
                } else {
                    return row;
                }
            }
        },
        { 
            title: "Movement", 
            field: "Movement",
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                    {row.movement_type == "stock" ? row.stock_movement  : row.price_movement}
                  </Box>;
                } else {
                    return row;
                }
            }
        },
        { 
            title: "Date", 
            field: "movement_date",
            render: (row, type) => {
                if(type === 'row'){
                    return <Box>
                    {moment(row.movement_date).format('DD-MMM-YYYY HH:mm:ss')}
                  </Box>;
                } else {
                    return row;
                }
            }
        },
      ]}
    />
  }

    render () {
        

        return <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
          <BreadCrumb />
          <h1 className="page-title">Movement Log</h1>
          </Container>
        </StickyPageHeader>
        <Card>
        <CardBody>
          <Row>
            <Col md="12">
              
              {this.customTable()}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.customDialog()}
        </Box>

    }

}

export default ItemMpvement;