import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SaleReturnList from "./list";
import SaleReturnDetail from "./saleReturnDetails";

const SaleReturn = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/all`}
        render={props => <SaleReturnList {...props} />}
      />
      <Route
        path={`${match.url}/details`}
        render={props => <SaleReturnDetail {...props} />}
      />
      <Redirect to="/sale-return/all" />
    </Switch>
);

export default SaleReturn;
