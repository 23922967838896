import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import moment from 'moment'
import * as XLSX from "xlsx";
import apiUtil from "../../../api/apiUtil";
import CSVfile from "../../../assets/files/wms-sales-template.xls"

class ImportOrderDialog extends Component {
  constructor(props) {
    super(props);
    
    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
      file: '',
      errMessages: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false,
      errMessages : []
    });
    this.props.onClose();
  }

  filePathset = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    this.setState({file: file});
    // if (file.type !== "application/vnd.ms-excel" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    //   apiUtil.toast("Only excel files allowed!", "error");
    //   this.setState({file: ""});
    // } else {
    //   this.setState({file: file});
    // }
  }

  readFile = () => {
    let f = this.state.file;
    if (f === "") {
      apiUtil.toast("No file is selected yet.", "error");
      return false;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = XLSX.read(bstr, { type: "binary"});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      if (data.length <= 0) {
        apiUtil.toast('Empty data!', 'error');
        return false;
      }

      let res = [];
      data.map(function(v,k){
        res.push(v.join("|||"));
      });

      let jsonData = this.convertToJson(res);
      
      let errMessages = []
      this.setState({errMessages})
      jsonData.forEach((row, i) => {
        if(row['handling_in_date'] == '' || row['handling_in_date'] == null || row['handling_in_date'] == "Invalid date") {
            errMessages.push(`Date is invalid at row ${i+2}.`)
        }

        if(row['end_user_name'] == '' || row['end_user_name'] == null) {
           errMessages.push(`Company code is empty at row ${i+2}.`)
        }
        if(row['total_kg'] == '' || row['total_kg'] == null || isNaN(row['total_kg'])) {
          errMessages.push(`Total Weight is invalid at row ${i+2}.`)
        }

        if(row['total_package'] == '' || row['total_package'] == null || isNaN(row['total_package'])) {
          errMessages.push(`Total package is invalid at row ${i+2}.`)
        }

        if(row['remarks'] == '' ||  row['remarks'] == null) {
          errMessages.push(`Remark is empty at row ${i+2}.`)
        }
        if(row['order_items'].length  < 1) {
          errMessages.push(`Order items is empty at row ${i+2}.`)
        }
      })
      
      if (errMessages.length > 0 ) {
        this.setState({errMessages})
      } else {
      this.props.onImport(jsonData);
      this.setState({modal: false});
      }
      
    };
    reader.readAsBinaryString(f);
  }

  convertToJson = csv => {
    let lines = csv;
    let result = [];
    let headers = lines[0].split("|||");

    for (let i = 1; i < lines.length; i++) {
      let obj = {
        // id: null,
        // delete: null
      };
      let currentline = lines[i].split("|||");

      for (let j = 0; j < headers.length; j++) {
        let key;

        switch(headers[j]) {
          case "Date (mm/dd/yyyy)":
            key = "handling_in_date";
            break;
          case "Company Code":
            key = "end_user_name";
            break;
          case "Total Weight (kg)":
            key = "total_kg";
            break;
          case "Total Packages":
            key = "total_package";
            break;
          case "Remarks":
            key = "remarks";
            break;
          case "Item 1 SKU":
            key = "item_1_sku";
            break;
          case "Item 1 Qty":
            key = "item_1_qty";
            break;
          case "Item 2 SKU":
            key = "item_2_sku";
            break;
          case "Item 2 Qty":
            key = "item_2_qty";
            break;
          case "Item 3 SKU":
            key = "item_3_sku";
            break;
          case "Item 3 Qty":
            key = "item_3_qty";
            break;
          default:
            key = "";
        }

        if (key !== "") {
          if (key == "handling_in_date") {
            obj[key] = moment(currentline[j]).format(apiUtil.getDefaultDateFormat())
          } else {
            obj[key] = currentline[j];
          }
          
        }
      }
      obj['order_items'] = []
      if (obj["item_1_sku"]) {
        let item = {}
        item['item_sku'] = obj["item_1_sku"]
        item['item_qty'] = isNaN(obj["item_1_qty"]) ? 1 : obj["item_1_qty"] || 1
        obj['order_items'].push(item)
      }

      if (obj["item_2_sku"]) {
        let item = {}
        item['item_sku'] = obj["item_2_sku"]
        item['item_qty'] = isNaN(obj["item_2_qty"]) ? 1 : obj["item_2_qty"] || 1
        obj['order_items'].push(item)
      }

      if (obj["item_3_sku"]) {
        let item = {}
        item['item_sku'] = obj["item_3_sku"]
        item['item_qty'] = isNaN(obj["item_3_qty"]) ? 1 : obj["item_3_qty"] || 1
        obj['order_items'].push(item)
      }

      result.push(obj);
    }

    
    return result;
  }

  setErrorMessages = () => {
    const errMessages = this.state.errMessages
      if(errMessages.length > 0) {
          return <Box clone pt={1} pb={1}>
            <Grid item xs={12}>
                {errMessages.map((err) => (
                <Box style={{margin:'1px'}}>
                  <Alert severity="error">
                      {err}
                  </Alert>
                  </Box>
                  ))} 
              </Grid>
            
              
          </Box>
      }
    
  }
  render(){
    return(
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="m">
        <ModalBody>
          <p><strong>To import Outbound Orders from excel:</strong></p>
          <ol>
            <li>Download import template by clicking the link below</li>
            <li>Upload the sheet and click "Import Outbound Orders" button.</li>
          </ol>
          <input
            type="file"
            id="file"
            ref="fileUploader"
            accept=".xls,.xlsx"
            onChange={this.filePathset.bind(this)}
          />
          <br />
          {/* <a href="/CSVfile" download></a> */}
          <Link to={CSVfile} target="_blank" download style={{ textDecoration: 'none' }}>Download Template</Link>
          <br />
          {this.state.errMessages && (this.setErrorMessages())}
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={() => this.readFile()} >
            Import Outbound Orders
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ImportOrderDialog;