import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import moment from 'moment'
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

class WareHouseDetails extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            data: '',
            isLoading: false,
            tabValue: 0,
            center: {
                lat: 59.95,
                lng: 30.33
              },
              zoom: 11
        }
    }
    
    
    componentDidMount() {
        let urlQuery = queryString.parse(window.location.search);
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    console.log(data)
                    this.setState({
                        data
                    })
                }
            })
        }
    }
    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('warehouses/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {

                this.setState({
                    // center[lat]: data.result.latitude,
                    // center[lng]: data.result.latitude,
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                // this.setState(prevState => ({
                //     center: {                   // object that we want to update
                //         ...prevState.center,    // keep all other key-value pairs
                //         lat: data.result.latitude,       // update the value of specific key
                //         lng: data.result.longitude,       // update the value of specific key
                //     }
                // }))
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    callParentWareHouseApi = ( callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
        let data ={
            parent_warehouse_id : null,
        }
        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('warehouses', data, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                // console.log('data result', data.result);
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }

    renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
        position: { lat: this.state.date.latitude, lng: this.state.date.longitude },
        map,
        title: this.state.data.name
        });
        return marker;
       };

    itemDetailPage = () => {
        return <>
        <Box mt={5}>
            <Grid container xs={12}>
                <Grid item xs={6}>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Warehouse Name</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.name && this.state.data.name !== null ? this.state.data.name :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Location</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.location && this.state.data.location !== null ? this.state.data.location :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Postal Code</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.postal_code && this.state.data.postal_code !== null ? this.state.data.postal_code :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Address</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.address && this.state.data.address !== null && this.state.data.address !== '' ? this.state.data.address :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Contact Number</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.contact_number && this.state.data.contact_number !== null && this.state.data.contact_number > 0 ? this.state.data.contact_number :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    {/* <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>parent warehouses id</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.parent_warehouses_id && this.state.data.parent_warehouses_id !== null ?  this.state.data.parent_warehouses_id:'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid> */}
                    {this.state.data.parent_warehouses_id && this.state.data.parent_warehouses_id != ''? 
                    <Grid container xs={12} className="mt-4">
                        <Typography className='fw-bolder mb-2'>Parent Warehouse</Typography>
                        <table className="stock_table">
                            <tr>
                                <th rowspan="2">WAREHOUSE NAME</th>
                                <th colspan="3">PARENT WAREHOUSE INFORMATION</th>
                            </tr>
                            <tr>
                                <th>NAME</th>
                                <th>LOCATION</th>
                                <th>CONTACT NUMBER</th>
                            </tr>
                            <tr>
                                <td>{this.state.data && this.state.data.name && this.state.data.name}</td>
                                <td>{this.state.data.parent_warehouse.name && this.state.data.parent_warehouse.name}</td>
                                <td>{this.state.data.parent_warehouse.location && this.state.data.parent_warehouse.location}</td>
                                <td>{this.state.data.contact_number && this.state.data.contact_number && this.state.data.contact_number }</td>
                            </tr>
                        </table>
                    </Grid>
                    : ''
                     }
                    
                </Grid>
                <Grid item xs={6}>
                    {(this.state.data.latitude && this.state.data.latitude != null) || (this.state.data.longitude && this.state.data.longitude != null) && (this.state.data.location && this.state.data.location != null) ?
                <div style={{ height: '250px', width: '100%' }} className="border">
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAwOYYFZG7Kk1oICHMYpqfX12uMSSlCCKE' }}
                    defaultCenter={{lat:this.state.data.latitude,lng:this.state.data.longitude}}
                    defaultZoom={15}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    >
                    <AnyReactComponent
                        lat={this.state.data.latitude}
                        lng={this.state.data.longitude}
                        text={this.state.data.name}
                    />
                    </GoogleMapReact>
                </div> : ''
                }
                </Grid>
            </Grid>
            
        </Box>
        
        </>
    }

    // itemHistory = () => {
    //     return <>
    //         <Box mt={5}>
    //             <Grid container xs={12}>
    //             {this.state.data && this.state.data.item_histories.length > 0 &&
    //                 <Box>
    //                     <Grid container xs={12} >
                            
    //                             <Grid item xs={4}><Box m={2}><Typography variant='subtitle1'>Date</Typography> </Box></Grid>
    //                             <Grid item xs={8}><Box m={2}><Typography variant='subtitle1'>Detail</Typography> </Box></Grid>
                            
    //                     </Grid>
    //                     {this.state.data.item_histories.map(his => {
    //                         return <>
    //                         <Grid container xs={12}>
    //                             <Grid item xs={4} ><Box m={1}><span style={{fontStyle:"italic"}}>{moment(his.created_at).format('DD-MMM-YYYY HH:mm:ss')}</span></Box></Grid>
    //                             <Grid item xs={8}><Box m={1}><span style={{fontStyle:"oblique"}}>{his.change_log}.</span> <span style={{fontWeight:"600"}}>{his.history_note !== null ? his.history_note: '' }</span> <span style={{fontStyle:"italic"}}>by {his.admin_name}</span> </Box></Grid>
    //                         </Grid>
    //                         </>
    //                     })}
    //                 </Box>
                    
    //             }
    //             </Grid>
    //         </Box>
    //     </>
    // }

    // importedImglist = () => {
    //     return <>
    //     {this.state.data && this.state.data.item_images.length > 0 ?
        
    //     this.state.data.item_images.map((img)=>{
            
    //        return<img src={img.image_url} width="100px" height="auto" style={{margin:"5px"}}/>
    //     }) : "" }

    //     </>
    // }
    getDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="Warehouse Details" {...a11yProps(0)} />
          {/* <Tab label="History" {...a11yProps(1)} /> */}
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={this.state.tabValue} index={0}>
        {this.itemDetailPage()}
      </TabPanel>
      {/* <TabPanel value={this.state.tabValue} index={1}>
        {this.itemHistory()}
      </TabPanel> */}
      {/* <TabPanel value={this.state.tabValue} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
        </>
    }
    render() {
        return <Box><StickyPageHeader>
            <Container>
                <BreadCrumb />
                <h1 className="page-title">Warehouse Details</h1>
                <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} />
                <IconButton className="btn-theme btn-theme-1" label="Edit " icon="edit" onClick={() => this.props.history.push('./edit?id='+this.state.dataId)} />
                {/* <IconButton className="btn-theme btn-theme-1" label="Adjust" icon="edit" onClick={() => this.props.history.push('./movement-form?id='+this.state.dataId)} /> */}
                </Container>
            </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Box mt={2} >
                   {/* <Typography variant="h5"> {this.state.data.name}</Typography> */}
                   {this.getDetails()}
                  </Box>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                
            </Container>
      </Box>

    }
}

export default WareHouseDetails;