import React, { useRef, useState, useContext, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { AuthContext } from "../../components/Auth/AuthDataProvider";
import authApi from '../../api/Authentication';
import apiUtil from '../../api/apiUtil';

import logo from '../../assets/img/logo_black.png';

import Footer from '../Footer/Footer.jsx'
import { RoleAccessService } from '../../data/role-access';

export default function SignIn() {
  let history = useHistory();

  const { clearStorage, token, refreshToken, authenticated, setAuthenticated, setToken, setCurrentRole, setAccessToken, setRefreshToken, setTokenType, setUser, setAppPermission, setUniqueRolePermissions } = useContext(AuthContext);
  
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [validatedResetEmail, setValidatedResetEmail] = useState(false);
  const [errorResetEmail, setErrorResetEmail] = useState(null);
  const [validatedPass, setValidatedPass] = useState(false);
  const [errorPass, setErrorPass] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPw, setShowForgotPw] = useState(false);
  const [sentEamil, setSentEmail] = useState(null);
  const [mailInput, setMailInput] = useState(null);
  const [timer, setTimer] = useState(null);
  const [showResetPass, setShowResetPass] = useState(false);

  const isMounted = useRef(null);
  const emailInput = useRef();
  const resetEmailInput = useRef();
  
  const pswInput = useRef();
  const axiosRef = useRef();

  useEffect(() => {

    //check if authenticated
    if(authenticated){
        history.push("/");
    }
    // executed when component mounted
    axiosRef.current = apiUtil.getCancelToken();
    isMounted.current = true;
    document.title = "Login | WMS";

    if(timer == true){
      setTimeout(() => { 
        window.location.href ="Sign-in";
    }, 3000)
    }

    return () => {
      // executed when unmount
      isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, [timer]);


  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);
    setValidatedPass(false);
    setErrorPass(null);
    
    if (emailInput.current.lastChild.firstChild.value === '') {
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if (pswInput.current.lastChild.firstChild.value === '') {
      setValidatedPass(true);
      setErrorPass('Please enter your password.');
    }

    if (form.checkValidity() === true) {
      setIsLoading(true);

      authApi.login(emailInput.current.lastChild.firstChild.value, pswInput.current.lastChild.firstChild.value, axiosRef.current.token)
        .then((result) => {
          window.localStorage.setItem("password", window.btoa(pswInput.current.lastChild.firstChild.value));
          apiUtil.parseResult(result, (data) => {

            let appAccessPermissions = [];

            if (data && data.owner_info.application_registrations.length > 0) {
              data.owner_info.application_registrations.forEach((el) => {
                if (el.name === "AUTH") {
                  appAccessPermissions.push("auth");
                } else if (el.name === "WLS") {
                  appAccessPermissions.push("wls");
                } else if (el.name === "WMS") {
                  appAccessPermissions.push("wms");
                }
              });

              window.localStorage.setItem("appPermission", appAccessPermissions);
            }


            //Grouping the permissions of associated Users
            let uniqueRolePermissions = []
            if (data && data.owner_info && data.owner_info.roles[0].permissions.length > 0) {
              // uniqueRolePermissions = [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]; // [ 'A', 'B']
              uniqueRolePermissions = data.owner_info.roles[0].permissions.filter(dd => dd.application_registration_id == 2 || dd.application_registration_id == 3); // [ 'A', 'B']
              
              window.localStorage.setItem("uniqueRolePermissions", JSON.stringify(uniqueRolePermissions));
            }
            setAuthenticated(true);
            setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
            setAccessToken(data.token.access_token);
            setRefreshToken(data.token.refresh_token);
            setTokenType(data.token.token_type);
            setToken(JSON.stringify(data.token));
            setUser(JSON.stringify(data));
            setAppPermission(appAccessPermissions);
            setUniqueRolePermissions(uniqueRolePermissions);

            let grantedMenu = new RoleAccessService;
            let role = localStorage.getItem('current_role');
            if (!grantedMenu.isShouldShowMenu(role,"Dashboard")) {
                if (grantedMenu.isPermissionActionAllowed(role, "Customer Browse")) {
                  history.push("/customers")
                } else if (grantedMenu.isShouldShowMenu(role, "Outbound Orders")) {
                  history.push("/sales-orders")
                } else if (grantedMenu.isShouldShowMenu(role, "Item List")) {
                  history.push("/items/all-items")
                } else if (grantedMenu.isShouldShowMenu(role, "General Report")){
                  history.push("/report/sales")
                } else if (grantedMenu.isShouldShowMenu(role, "Warehouse")) {
                  history.push("/warehouse")
                }
            } else {
              history.push("/dashboard");
            }
            
          }, (error, type) => {
            setError({ title: error });
            setIsLoading(false);
          });
      });
    }
  };

  const handleResetSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedResetEmail(false);
    setErrorResetEmail(null);
    setSentEmail(null)
    if(resetEmailInput.current.lastChild.firstChild.value === ''){
      setValidatedResetEmail(true);
      setErrorResetEmail('Please enter your email address.');
    } else {
      let email = resetEmailInput.current.lastChild.firstChild.value
      let param ={
        email
      }
      setIsLoading(true);
      authApi.validEmail('validate/email', param)
      .then((result) => {
      apiUtil.parseResult(result, (data) => {
        callForgotPasswordApi(email)
        setIsLoading(false);
      }, (error, type) => {
        console.log("error",error)
        setErrorResetEmail(error);
        setIsLoading(false);
      });
      });
    }
  }

  const callForgotPasswordApi = email => {
    let param = {
      proper_mail: email,
      email
    }
    setIsLoading(true);
      authApi.validEmail('forgot/password', param)
      .then((result) => {

      apiUtil.parseResult(result, (data) => {
        setSentEmail('Please check your email for a message with your code')
        setIsLoading(false)
        setTimer(true)
        setMailInput(" ");
      }, (error, type) => {
        setIsLoading(false);
      });
      });
  }

return (
      <div className="signin">
      <div className="cover">
        <div className="cover__text">Equipment you can count on. People you can trust</div>
        <div className="cover__footer"><Footer /></div>
      </div>
      <div className="login" component="main" maxwidth="sm">
        <div className="top-menu">
          <div className="logo"><img src={logo} alt="" /></div>
        </div>
        {
          error && !isLoading
          &&
          <Box textAlign="left" mt={3} mb={3}>
            <Alert severity="error">
              {error.title && <b>{error.title}</b>}
              {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
                return <div key={i}>{e}</div>
              })}
            </Alert>
          </Box>
        } 
        {
          errorResetEmail && !isLoading
          &&
          <Box textAlign="left" mt={0} mb={3}>
            <Alert severity="error">
              {errorResetEmail}
            </Alert>
          </Box>
        }
        {
          sentEamil && !isLoading
          &&
          <Box textAlign="left" mt={0} mb={3}>
            <Alert severity="success">
              {sentEamil}
            </Alert>
          </Box>
        }
        <div className="form-wrapper">
        {showResetPass == true?
        <>
            <form noValidate 
            // onSubmit={resetSubmit}
            >
            <Box  mb={1}>
              <div className="form-title" textAlign="left">
              Reset Password
              </div>
              <div className="form-title-inner" textAlign="left">
              Welcome. Let's set a new password for your account. Don't lose it this time okay!
              </div>
              <div className="form-title-bg">
                <ul>
                  <li>Password must be 8-20 characters long.</li>
                  <li>At least one capital letter, a number & a symbol (optional).</li>
                </ul>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                error={validatedPass} 
                helperText={errorPass}
                ref={pswInput}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained" 
              color="primary"
              disabled={isLoading}
            >
              <Box pl={2} pr={2} style={{ color: 'white' }}>Save Password</Box>
              {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
            </Button>
            </form>
        </>
      :<>
        {showForgotPw === false ?
      <form noValidate onSubmit={handleSubmit}>

          <Box textAlign="center" mb={1}>
          <div className="form-title">
          Welcome back!
          </div>
          <div className="form-description">
          Login into your dash express dashboard with your credentials
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={validatedEmail} 
            helperText={errorEmail}
            ref={emailInput}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={validatedPass} 
            helperText={errorPass}
            ref={pswInput}
          />
        </Box>
        {/* <Box mb={1}>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Box> */}
        <Box mb={2}>
          <a className="resetPass" onClick={() => setShowForgotPw(true)}>
            <u>Forgot your Password?</u></a>
        </Box> 
        <Button
          type="submit"
          fullWidth
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN IN</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
        </form>
       : (
        <form onSubmit={handleResetSubmit}><Box textAlign="center" mb={3}>
        <div className="form-title">
        Reset Password
        </div>
        <div className="form-description">
        Enter your email address below and we will guide you on how to reset your password.
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          value={mailInput}
          onChange={(e)=> {
            setMailInput(e.target.value);
          }}
          autoFocus
          error={validatedResetEmail} 
          helperText={errorResetEmail}
          ref={resetEmailInput}
        />
        {console.log("mailInput",mailInput)}
      </Box>
      
      <Button
        type="submit"
        fullWidth
        variant="contained" 
        color="primary"
        disabled={isLoading}
      >
        <Box pl={2} pr={2} style={{ color: 'white' }}>Reset Password</Box>
        {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
      </Button>
      <Box mt={2}><a  className="resetPass" onClick={() => setShowForgotPw(false)}><u>Back to Login</u></a></Box> </form>
      
      )}
      </>
}
        </div>
      </div>
      </div>
  );
}