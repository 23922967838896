import React, { Component } from "react";
import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Label,
  Container
} from "reactstrap";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import queryString from 'query-string';
import Select from 'react-select';
import Loading from "react-fullscreen-loading";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";
import DepartmentForm from "../../components/Forms/DepartmentForm";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";


  const CustomerSchema= Yup.object({
    company_name: Yup.string().min(2, "Too Short!").required("Please enter name!"),
    company_code: Yup.string().min(2, "Too Short!").required("Please enter code!"),
    application_registration_ids: Yup.string().required('Must check at least one!'),
    address1: Yup.string().required('Please input address!'),
    address2: Yup.string().nullable(),
    postal_code: Yup.string().nullable() 
                    .test(
                      "postal_code",
                      "postal_code must be 6 digit number",
                      (value) => apiUtil.postalCodeValidation(value) == true
                    ),
    contact_no1: Yup.string()
                    .required('Please enter a contact number!')
                    .test(
                      "contact_no1",
                      "Phone number is invalid",
                      (value) => apiUtil.phoneValidation(value) == true
                    ),
    contact_no2: Yup.string()
                    .nullable()
                    .test(
                      "contact_no2",
                      "Phone number is invalid",
                      (value) => apiUtil.phoneValidation2(value) == true
                    ),
    company_fax: Yup.string().nullable(),
    company_email: Yup.string()
                      .nullable()
                      .test(
                        "company_email",
                        "Email is invalid",
                        (value) => apiUtil.emailValidation(value) == true
                      ),
    // handling_fee_type_id: Yup.number().oneOf([4, 5, 6]),
    // handling_fee_in: Yup.number().required('Input handling in fee.'),
    // handling_fee_out: Yup.number().required('Input handling out fee.'),
    scanned_delivery_order: Yup.boolean().nullable(),
    volume_limit: Yup.number().nullable(),
    volume_over_limit_fee: Yup.number().nullable(),
    rental_fee_type_id: Yup.number().oneOf([4, 5, 6]).nullable(),
    // rental_fee: Yup.number().required('Please input rental fee.'),
    // email_alert_type_id: Yup.number().oneOf([3, 4, 5]).nullable(),
    // minimum_charge: Yup.number().required('Please input min. charge.'),
    admin_first_name: Yup.string().min(2, "Too Short!"),
    admin_last_name: Yup.string().nullable(),
    admin_email: Yup.string().email().nullable(),
    admin_password: Yup.string().nullable(),
    admin_confirm_password: Yup.string().oneOf([Yup.ref('admin_password'), null], 'Passwords must match').nullable()
  });

  const CustomerSchema2= Yup.object({
    company_name: Yup.string().min(2, "Too Short!").required("Please enter name!"),
    company_code: Yup.string().min(2, "Too Short!").required("Please enter code!"),
    application_registration_ids: Yup.string().required('Must check at least one!'),
    address1: Yup.string().required('Please input address!'),
    address2: Yup.string().nullable(),
    postal_code: Yup.string().nullable() 
                    .test(
                      "postal_code",
                      "postal_code must be 6 digit number",
                      (value) => apiUtil.postalCodeValidation(value) == true
                    ),
    contact_no1: Yup.string()
                    .required('Please enter a contact number!')
                    .test(
                      "contact_no1",
                      "Phone number is invalid",
                      (value) => apiUtil.phoneValidation(value) == true
                    ),
    contact_no2: Yup.string()
                    .nullable()
                    .test(
                      "contact_no2",
                      "Phone number is invalid",
                      (value) => apiUtil.phoneValidation2(value) == true
                    ),
    company_fax: Yup.string().nullable(),
    company_email: Yup.string()
                      .nullable()
                      .test(
                        "company_email",
                        "Email is invalid",
                        (value) => apiUtil.emailValidation(value) == true
                      ),
    // handling_fee_type_id: Yup.number().oneOf([4, 5, 6]),
    // handling_fee_in: Yup.number().required('Input handling in fee.'),
    // handling_fee_out: Yup.number().required('Input handling out fee.'),
    scanned_delivery_order: Yup.boolean().nullable(),
    volume_limit: Yup.number().nullable(),
    volume_over_limit_fee: Yup.number().nullable(),
    rental_fee_type_id: Yup.number().oneOf([4, 5, 6]).nullable(),
    // rental_fee: Yup.number().required('Please input rental fee.'),
    // email_alert_type_id: Yup.number().oneOf([3, 4, 5]).nullable(),
    // minimum_charge: Yup.number().required('Please input min. charge.'),
    admin_first_name: Yup.string().min(2, "Too Short!").required('Please enter first name for admin email'),
    admin_last_name: Yup.string().nullable(),
    admin_email: Yup.string().email().nullable(),
    admin_password: Yup.string().nullable(),
    admin_confirm_password: Yup.string().oneOf([Yup.ref('admin_password'), null], 'Passwords must match').nullable()
  });



const handlingFeeTypeOptions = [
  { value: '4', label: 'Per Quantity' },
  { value: '5', label: 'Per Volume/M3' },
  { value: '6', label: 'Per Transaction' }
];

const alertTypeOptions = [
  { value: '4', label: 'Low Quantity Limit' },
  { value: '3', label: 'Expiry Date' },
  { value: '5', label: 'N/A' }
]


class CustomerForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      dataId: '',
      oldAdminId: 0,
      errors: {},
      isLoading: false,
      pageLoading: false,
      showFeeFields: false,
      showScanFields: false,
      oldData: null,
      emailCheck:'',
      formData: {
        company_name: '',
        company_code: '',
        application_registration_ids: '2,',
        address1: '',
        address2: '',
        postal_code: '',
        contact_no1: '',
        contact_no2: '',
        company_email: '',
        handling_fee_type_id: '4',
        handling_fee_type:'Per Quantity',
        handling_fee_in: '',
        handling_fee_out: '',
        scanned_delivery_order: false,
        volume_limit: '',
        volume_over_limit_fee: '',
        rental_fee_type_id: '4',
        rental_fee: '',
        email_alert_type_id: '4',
        email_alert_type:'Low Quantity Limit',
        minimum_charge: '100',
        admin_first_name: '',
        admin_last_name: '',
        admin_password: '',
        admin_confirm_password: '',
        admin_email: '',
        admin_email_err: '',
        admin_email_validated: false,
        departments: [],
       
      }
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleRemoveDepartment = this.handleRemoveDepartment.bind(this);
    this.handleFeeChanged = this.handleFeeChanged.bind(this);
    this.handleScanningField = this.handleScanningField.bind(this);
    this.handleDepartmentInputChange = this.handleDepartmentInputChange.bind(this);
    this.addContactToState = this.addContactToState.bind(this);
    this.deleteContactToState = this.deleteContactToState.bind(this);
    this.populateData = this.populateData.bind(this);
  }

  componentDidMount () {
    this._isMounted = true;

    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        pageLoading: true,
        dataId: urlQuery.id
      });
      this.getData(urlQuery.id);

      document.title = "Edit Customer | WMS";
    } else {
      document.title = "Create Customer | WMS";      
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getData = id => {
    customerApi.show(id)
    .then(data => {
      if (data.status === 200) {
        this.populateData(data.data.customer);
      } else {
        apiUtil.toast(data.errorMessage, 'warning');
      }
    })
    .catch(error => {
      apiUtil.toast(error.message, 'error');
    })
  }

  populateData = data => {
    let formData = this.state.formData;

    for (const key in formData) {
      formData[key] = data[key];
    }

    let appRegisterIds = '';
    for (let key in data.application_registrations) {
      if (data.application_registrations[key].name === "WMS") {
        appRegisterIds += "2,";
      }

      if (data.application_registrations[key].name === "WLS") {
        appRegisterIds += "1,";
      }
    }

    formData['application_registration_ids'] = appRegisterIds;
    formData['rental_fee_type_id'] = data.rental_fee_type_id ? data.rental_fee_type_id.toString() : '';
    let departments = [];
    let phoneRegEx = /^[+]?\d+$/;
    data.departments.forEach((department, index) => {
      let contacts = [];
      if (department.id !== "") {
        department.contact_people.forEach((contact, index2)=> {
          if (contact.first_name === null) contact.first_name = '';
          if (contact.last_name === null) contact.last_name = '';
         
          if (contact.id !== "") {
            contacts.push({
              delete: false,
              id: contact.id,
              name: contact.first_name + ' ' + contact.last_name,
              first_name: contact.first_name,
              last_name: contact.last_name,
              contact_no: contact.contact_no,
              email: contact.email
            });
          }
        });

        departments.push({
          delete: false,
          department_id: department.id,
          department_name: department.name,
          contact_persons: contacts
        });
      }
    });

    formData['departments'] = departments;

    formData['address1'] = data.customer_addresses[0].address_line1;
    formData['address2'] = data.customer_addresses[0].address_line2;
    formData['postal_code'] = data.customer_addresses[0].postal_code;

    // formData['admin_first_name'] = data.customer_admins[0] === undefined ? '' : data.customer_admins[0].first_name;
    // formData['admin_last_name'] = data.customer_admins[0] === undefined ? '' : data.customer_admins[0].last_name;
    // formData['admin_email'] = data.customer_admins[0] === undefined ? '' : data.customer_admins[0].email;

    formData['admin_first_name'] = data.admins[0] === undefined ? '' : data.admins[0].first_name;
    formData['admin_last_name'] = data.admins[0] === undefined ? '' : data.admins[0].last_name;
    formData['admin_email'] = data.admins[0] === undefined ? '' : data.admins[0].email;
    this.setState({
      emailCheck : data.admins[0] === undefined ? '' : data.admins[0].email
    })
    formData['admin_password'] = '';
    formData['admin_confirm_password'] = '';

    formData.handling_fee_type_id = data.handling_fee_type_id?data.handling_fee_type_id.toString():"4";
    formData.email_alert_type_id = data.email_alert_type_id?data.email_alert_type_id.toString():"4";

    handlingFeeTypeOptions.map(function(v,k){ if(data.handling_fee_type_id == v.value){ formData.handling_fee_type=v.label;} });
    alertTypeOptions.map(function(v,k){ if(data.email_alert_type_id == v.value){ formData.email_alert_type=v.label;} });

    this.setState({
      oldData: data,
      pageLoading: false,
      formData: formData,
      oldAdminId: data.customer_admins[0] === undefined ? 0 : data.customer_admins[0].id,
      showFeeFields:formData.handling_fee_type_id === "5"?true:false
    });
  }

  addDepartment = element => {
    let formData = this.state.formData;
    let departments = formData.departments;
    departments.push({
      delete: false,
      department_id: null,
      department_name: '',
      contact_persons: [
        {
          id: null,
          name: '',
          first_name: '',
          last_name: '',
          contact_no: '',
          email: ''
        }
      ]
    });

    formData.departments = departments;
    this.setState({
      formData: formData
    });
  }

  handleRemoveDepartment = index => {
    let formData = this.state.formData;
    let departments = formData.departments;
    
    departments[index]['delete'] = true;
    formData.departments = departments;
    this.setState({
      formData: formData
    });
  }

  handleFeeChanged = element => {
    let showFeeFields = false;
    let formData = this.state.formData;
    formData.handling_fee_type_id = element.value;
    handlingFeeTypeOptions.map(function(v,k){ if(element.value == v.value){ formData.handling_fee_type=v.label;} });


    if (element.value === "5") {
      showFeeFields = true;
    }

    this.setState({
      showFeeFields: showFeeFields,
      formData: formData
    });
  }

  handleScanningField = element => {
    let showScanFields = false;
    let formData = this.state.formData;
    formData.scanned_delivery_order = false;

    if (element.currentTarget.value === "1") {
      showScanFields = true;
      formData.scanned_delivery_order = true;
    }

    this.setState({
      showScanFields: showScanFields,
      formData: formData
    });
  }

  handleApplicationRegisterCheck (event) {
    let formState = this.state.formData;

    // console.log('formState', formState)
    // console.log('checkIds', formState['application_registration_ids'])
    let checkIds = formState['application_registration_ids'];

    if (
      event.target.checked &&
      !checkIds.includes(event.target.value)
    ) {
      checkIds += event.target.value + ',';
    }

    if (
      !event.target.checked &&
      checkIds.includes(event.target.value)
    ) {
      checkIds = checkIds.replace(event.target.value + ',', '');
    }

    formState.application_registration_ids = checkIds;

    this.setState({
      formData: formState
    });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;
    // formState[event.target.name].replace(/^\s+|\s+$/gm,'');
    this.setState({
      formData: formState
    });
  }

  // handleInputChangePostalCode = event => {
  //   let formState = this.state.formData;
  //     formState[event.target.name] = Math.max(0, parseInt(event.target.value) ).toString().slice(0,6);

  //   this.setState({
  //     formData: formState
  //   });
  // }

  handleSelectChange = (event, name) => {
    let formState = this.state.formData;
    formState[name] = event.value;

    if(name == "email_alert_type_id"){
      alertTypeOptions.map(function(v,k){ if(event.value == v.value){ formState.email_alert_type=v.label;} });
    }

    this.setState({
      formData: formState
    });
  }

  handleDepartmentInputChange = (index, name, value, optionalIndex=false) => {
    let formData = this.state.formData;
    let departments = formData.departments;

    if (name.includes(".") && optionalIndex !== false) {
      let properties = name.split(".");
      if (properties[0] !== undefined && properties[1] !== undefined) {
        departments[index][properties[0]][optionalIndex][properties[1]] = value;
      }
    } else {
      departments[index][name] = value;
    }

    formData.departments = departments;

    this.setState({
      formData: formData
    });
  }

  addContactToState = index => {
    let formData = this.state.formData;
    let departments = formData.departments;
    departments[index]['contact_persons'].push({
      delete: false,
      id: '',
      name: '',
      first_name: '',
      last_name: '',
      contact_no: '',
      email: ''
    });

    formData.departments = departments;

    this.setState({
      formData: formData
    });
  }

  deleteContactToState = (index, key) => {

    let formData = this.state.formData;
    let departments = formData.departments;
    departments[index]['contact_persons'][key]['delete'] = true
    formData.departments = departments;

    this.setState({
      formData: formData
    });
  }

  handleSubmit = values => {

    values.company_name = values.company_name? values.company_name.replace(/^\s+|\s+$/gm,''): values.company_name
    values.company_code = values.company_code? values.company_code.replace(/^\s+|\s+$/gm,''): values.company_code
    values.address1 = values.address1? values.address1.replace(/^\s+|\s+$/gm,''): values.address1
    values.address2 = values.address2? values.address2.replace(/^\s+|\s+$/gm,''): values.address2
    values.company_fax = values.company_fax?values.company_fax.replace(/^\s+|\s+$/gm,'') : values.company_fax
    values.company_email = values.company_email?values.company_email.replace(/^\s+|\s+$/gm,''): values.company_email
    // if (values.admin_email === "" || values.admin_email === undefined) {
    //   apiUtil.toast('Input administrator email first!', 'error');
    //   return false;
    // }

    //  if (values.admin_email != "" && values.admin_first_name == '') {
    //   CustomerSchema = Yup.object({
    //     admin_first_name: Yup.string().min(2, "Too Short!").required('Please input first name!'),
    //   });
    //   return false;
    // }

    // if (values.postal_code.length != 6) {
    //   apiUtil.toast('invalid postal code(must be 6 digit number)!', 'error');
    //   return false;
    // }

    let contact = values.contact_no1;
    let phoneRegEx = /^[+]?\d+$/;
    if(!phoneRegEx.test(contact) || contact === '' || contact.length < 8 || contact.length > 13 ){
      apiUtil.toast('contact number must be between 8 and 13', 'error');
      return
    } 

    let notification_emailValidated = true
    if(this.state.notification_email !== '' && !apiUtil.emailValidation(this.state.notification_email)){
      notification_emailValidated = false
      this.setState({
        notification_emailValidated: true
      });
    }

    values.is_active = true;
    values.scanned_delivery_order = true;

    if(this.state.admin_email_validated ) return

    values.addresses = [];
    if (values.address1 !== undefined) {
      let oldData = this.state.oldData;
      let addressId = '';
      if (oldData !== null) {
        addressId = oldData.customer_addresses[0].id;
      }

      values.addresses.push({
        id: addressId,
        name: 'Default',
        address_line1: values.address1,
        address_line2: values.address2 !== undefined ? values.address2 : '',
        is_main: true,
        contact_name: values.company_name,
        city: '',
        state: '',
        postal_code: values.postal_code !== undefined ? values.postal_code : ''
      });
    }

    let departments = this.state.formData.departments;
    if (departments.length > 0) {
      for (let i=0; i<departments.length; i++) {
        if (departments[i] !== undefined) {
          if (departments[i].department_name == '' || departments[i].department_name == null ){
            apiUtil.toast('Department name can\'t be blank', 'error');
            return
          }
          
          for (let j=0; j<departments[i]['contact_persons'].length; j++) {
            if (departments[i]['contact_persons'][j].delete != true) {
              if (departments[i]['contact_persons'][j].contact_no && (!phoneRegEx.test(departments[i]['contact_persons'][j].contact_no)  || departments[i]['contact_persons'][j].contact_no.length < 8 || departments[i]['contact_persons'][j].contact_no.length > 13)){
                apiUtil.toast('Department '+ ++i + ' Contact person ' + ++j + ' contact number is invalid', 'error');
                return
              }
              let dep_email = departments[i]['contact_persons'][j].email 
              if( dep_email && !apiUtil.emailValidation(dep_email)) {
                apiUtil.toast('Department '+ ++i + ' Contact person ' + ++j + ' email is invalid', 'error');
                return
              }
              let fullName = departments[i]['contact_persons'][j]['name'].split(" ");
              departments[i]['contact_persons'][j]['first_name'] = fullName[0] !== undefined ? fullName[0] : "";
              departments[i]['contact_persons'][j]['last_name'] = fullName[1] !== undefined ? fullName[1] : "";
              // delete departments[i]['contact_persons'][j]['name'];
           }
          }
        }
      }
    }
    values.departments = departments;


    if (this.state.dataId === "") { // create
      if (values.admin_password !== "") {
        values.customer_admins = [{
          "first_name": values.admin_first_name,
          "last_name": values.admin_last_name,
          "postal_code": values.postal_code,
          "address": values.address1,
          "contact_no": values.contact_no1,
          "email": values.admin_email,
          "is_default_password": false,
          "password": values.admin_password
        }];
      }

      this.setState({
        isLoading: true
      });

      customerApi.create(values)
      .then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {

          // console.log('data', data.data.data.customer.id)
            this.setAdminAcc(values, data.data.data.customer.id);
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.log("here"); 
        apiUtil.toast(error.message, 'error');
      });
    } else { // update
      let id = this.state.dataId;
      
      values.id = id;

      if (values.admin_password !== "") {
        values.customer_admins = [{
          "id": this.state.oldAdminId,
          "first_name": values.admin_first_name,
          "last_name": values.admin_last_name,
          "postal_code": values.postal_code,
          "address": values.address1,
          "contact_no": values.contact_no1,
          "email": values.admin_email,
          "is_default_password": false,
          "password": values.admin_password,
          "remove_from_customer": false
        }];
      }

      this.setState({
        isLoading: true
      });

      customerApi.update(id, values)
      .then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.data.status === 200) {
          if(this.state.oldData && this.state.oldData.admins && this.state.oldData.admins.length == 0 && values.admin_email) {
            this.setAdminAcc(values, data.data.data.customer.id);
          } else {
            apiUtil.toast("Customer Updated.", 'success');
            this.props.history.push('../customers/all-customer');
          }
          
          
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      })
      .catch(error => apiUtil.toast(error.message, 'error'));
    }
  }

  setAdminAcc = (cus_admin, cus_id) => {

    if(cus_admin.admin_email == '' || cus_admin.admin_email == null) {
      apiUtil.toast("Customer Created", 'success');
      this.props.history.push('../customers/all-customer');
    } else {
      const { 
        REACT_APP_RESET_EMAIL_URL
      } = process.env;
      const PATH = 'admin/api/';
      let errorMessage = "";
      let status = 0;
  
      this.setState({
          pageLoading: true
        });
        const api = "admins";
        let URL = REACT_APP_RESET_EMAIL_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
  
      let data = {
         
          remove: false,
          is_default_password: true,
          password: "123456",
          first_name: cus_admin.admin_first_name? cus_admin.admin_first_name.replace(/^\s+|\s+$/gm,''): '',
          last_name: cus_admin.admin_last_name? cus_admin.admin_last_name.replace(/^\s+|\s+$/gm,''): '',
          email: cus_admin.admin_email? cus_admin.admin_email.replace(/^\s+|\s+$/gm,'') : '',
          // is_lds_only: true,
          wms_account: true,
          // is_own_data_only: true,
          is_system_role: true,
          is_customer_admin: true,
          customer_ids: cus_id.toString()
      };
      axios.post(
          URL,
          data,
          {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'app-name': 'WLS',
              }
          })
          .catch(error => {
              try {
                  status = error.response.status;
                  // if(status == 400) {
                  apiUtil.toast(error.response.data.error.email, 'error');
                  // }
                  if (error.response) {
                      // Request made and server responded
                      errorMessage = error.response.data;
                  } else if (error.request) {
                      // The request was made but no response was received
                      errorMessage = error.request;
                  } else {
                      // Something happened in setting up the request that triggered an Error
                      errorMessage = error.message;
                  console.log(errorMessage, 'asdf')

                  }
              } catch (err) {
                  errorMessage = 'Service Unavailable!'
              }
              this.setState({
                pageLoading: false,
            });
          }).then((response) => {
              apiUtil.toast("Sucess", 'success');
              this.props.history.push('../customers/all-customer');
          });
    }
  }

  searchAdminAcc = (email) => {

    const { 
      REACT_APP_RESET_EMAIL_URL
    } = process.env;
    const PATH = 'admin/api/';
    let errorMessage = "";
    let status = 0;
    const api = "admins";
    let URL = REACT_APP_RESET_EMAIL_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    
    this.setState({
      admin_email_err: '',
      admin_email_validated: false,
      isLoading: true,
    }, () => {

      let data = {
        email: email,
    };
    axios.get(
        URL,
        {
          params: data,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'app-name': 'WLS',
          }
        })
        .catch(error => {
            try {
                status = error.response.status;
                // if(status == 400) {
                apiUtil.toast(error.response.data.error.email, 'error');
                // }
                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                console.log(errorMessage, 'asdf')
  
                }
            } catch (err) {
                errorMessage = 'Service Unavailable!'
            }
            this.setState({
              emailCheckingLoading: false,
            })
        }).then((response) => {
            if(response.data.result && response.data.result.length > 0) {
              this.setState({
                admin_email_err: 'Admin email is already taken.',
                admin_email_validated: true,
              })
            } 
            this.setState({
              isLoading: false,
            })
        });

    })
    
  }
  
  render() {
    const theClass = this;

    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
        <Container>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? "Update Customer" : "Create Customer"}</h1>
          </Container>
        </StickyPageHeader>

        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <div className="container-fluid">
          <Formik
          enableReinitialize={true}
          initialValues={{
            company_name: this.state.formData.company_name,
            company_code: this.state.formData.company_code,
            application_registration_ids: this.state.formData.application_registration_ids,
            address1: this.state.formData.address1,
            address2: this.state.formData.address2,
            postal_code: this.state.formData.postal_code,
            contact_no1: this.state.formData.contact_no1,
            contact_no2: this.state.formData.contact_no2,
            company_email: this.state.formData.company_email,
            handling_fee_type_id: this.state.formData.handling_fee_type_id,
            handling_fee_in: this.state.formData.handling_fee_in,
            handling_fee_out: this.state.formData.handling_fee_out,
            scanned_delivery_order: this.state.formData.scanned_delivery_order,
            volume_limit: this.state.formData.volume_limit,
            volume_over_limit_fee: this.state.formData.volume_over_limit_fee,
            rental_fee_type_id: this.state.formData.rental_fee_type_id,
            rental_fee: this.state.formData.rental_fee,
            email_alert_type_id: this.state.formData.email_alert_type_id,
            minimum_charge: this.state.formData.minimum_charge,
            admin_first_name: this.state.formData.admin_first_name,
            admin_last_name: this.state.formData.admin_last_name,
            admin_password: this.state.formData.admin_password,
            admin_email: this.state.formData.admin_email,
          }}
          validationSchema={this.state.formData.admin_email == null || this.state.formData.admin_email == ''? CustomerSchema : CustomerSchema2}
          onSubmit={this.handleSubmit}>
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
            validateForm
          }) => (
            <Form>
              <Row className="mb-4">
                <Col xs="12" xl="6" lg="12">
                  <Card className="mt-3">
                    <CardTitle><h4>Company Info</h4></CardTitle>
                    <CardBody className="pt-0">
                      <Row>
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Company Name*
                          </Label>
                          <Field className="form-control form-input" name="company_name"  value={this.state.formData.company_name || ''} onChange={this.handleInputChange} />
                          {
                            errors.company_name && touched.company_name ? (
                            <div className="invalid-feedback d-block">
                            {errors.company_name}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Company Code*
                          </Label>
                          <Field className="form-control form-input" name="company_code" value={this.state.formData.company_code || ''} onChange={this.handleInputChange} />
                          {
                            errors.company_code && touched.company_code ? (
                            <div className="invalid-feedback d-block">
                            {errors.company_code}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Application Registration*
                          </Label>
                          {
                            errors.application_registration_ids && touched.application_registration_ids ? (
                              <div className="invalid-feedback d-block">
                              {errors.application_registration_ids}
                              </div>
                              ) : null
                          }
                          <div className="form-check form-input">
                            <Field type="checkbox" name="application_registration_ids" value="1" id="appRegisterLabel1" checked={this.state.formData.application_registration_ids.includes('1')} onChange={(e) => this.handleApplicationRegisterCheck(e)} />
                            <Label className="form-check-label ml-0" for="appRegisterLabel1">FMS</Label>

                            <Field type="checkbox" name="application_registration_ids" value="2" id="appRegisterLabel2" checked={this.state.formData.application_registration_ids.includes('2')} onChange={(e) => this.handleApplicationRegisterCheck(e)}  disabled/>
                            <Label className="form-check-label ml-0" for="appRegisterLabel2">WMS</Label>
                          </div>
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Address Line 1*
                          </Label>
                          <Field className="form-control form-input" component="textarea" name="address1" rows="5" value={this.state.formData.address1 || ''} onChange={this.handleInputChange} />
                          {
                            errors.address1 && touched.address1 ? (
                            <div className="invalid-feedback d-block">
                            {errors.address1}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Address Line 2
                          </Label>
                          <Field className="form-control form-input" component="textarea" name="address2" rows="5" value={this.state.formData.address2 || ''} onChange={this.handleInputChange} />
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Postal Code
                          </Label>
                          <Field className="form-control form-input" name="postal_code" value={this.state.formData.postal_code || ''} 
                          onChange={this.handleInputChange} />
                          {
                            errors.postal_code && touched.postal_code ? (
                            <div className="invalid-feedback d-block">
                            {errors.postal_code}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Office Phone 1*
                          </Label>
                          <Field className="form-control form-input" name="contact_no1" value={this.state.formData.contact_no1 || ''} onChange={this.handleInputChange} />
                          {
                            errors.contact_no1 && touched.contact_no1 ? (
                            <div className="invalid-feedback d-block">
                            {errors.contact_no1}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Office Phone 2
                          </Label>
                          <Field className="form-control form-input" name="contact_no2" value={this.state.formData.contact_no2 || ''} onChange={this.handleInputChange} />
                          {
                            errors.contact_no2 && touched.contact_no2 ? (
                            <div className="invalid-feedback d-block">
                            {errors.contact_no2}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Fax
                          </Label>
                          <Field className="form-control form-input" name="company_fax" value={this.state.formData.company_fax || ''} onChange={this.handleInputChange} />
                          {
                            errors.company_fax && touched.company_fax ? (
                            <div className="invalid-feedback d-block">
                            {errors.company_fax}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            WMS PIC Email
                          </Label>
                          <Field className="form-control form-input" type="email" name="company_email" value={this.state.formData.company_email || ''} onChange={this.handleInputChange} />
                          {
                            errors.company_email && touched.company_email ? (
                            <div className="invalid-feedback d-block">
                            {errors.company_email}
                            </div>
                            ) : null
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  {/* <Card className="mt-3">
                    <CardTitle><h4>Delivery Order</h4></CardTitle>
                    <CardBody className="pt-0">
                      <Row>
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Shipment Email Notification
                          </Label>
                          <div className="form-check form-input">
                            <Field type="radio" name="email_shipment_day" value="7" id="emailNotifLabel1" checked={this.state.formData.email_shipment_day === '7'} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="emailNotifLabel1">7</Label>

                            <Field type="radio" name="email_shipment_day" value="30" id="emailNotifLabel2" checked={this.state.formData.email_shipment_day === '30'} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="emailNotifLabel2">30</Label>

                            <Field type="radio" name="email_shipment_day" value="60" id="emailNotifLabel3" checked={this.state.formData.email_shipment_day === '60'} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="emailNotifLabel3">60</Label>

                            <Field type="radio" name="email_shipment_day" value="90" id="emailNotifLabel4" checked={this.state.formData.email_shipment_day === '90'} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="emailNotifLabel4">90</Label>

                            <Field type="radio" name="email_shipment_day" value="null" id="emailNotifLabel5" checked={this.state.formData.email_shipment_day === 'null'} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="emailNotifLabel5">N/A</Label>
                          </div>
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Delivery Order Scanning
                          </Label>
                          <div className="form-check form-input">
                            <Field type="radio" name="scanned_delivery_order" value="0" id="orderScanLabel1" checked={this.state.formData.scanned_delivery_order === false} onChange={this.handleScanningField} />
                            <Label className="form-check-label ml-0" for="orderScanLabel1">Disabled</Label>

                            <Field type="radio" name="scanned_delivery_order" value="1" id="orderScanLabel2" checked={this.state.formData.scanned_delivery_order === true} onChange={this.handleScanningField} />
                            <Label className="form-check-label ml-0" for="orderScanLabel2">Enabled</Label>
                          </div>
                        </Col>

                        {this.state.showScanFields && 
                          <div className="clearfix w-100">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Scanning Options
                              </Label>
                              <div className="form-check form-input">
                                <Field type="radio" name="scan_setting_id" value="2" id="orderScanTypeLabel1" checked={this.state.formData.scan_setting_id === '2'}  onChange={this.handleInputChange} />
                                <Label className="form-check-label" for="orderScanTypeLabel1">Lot Number</Label>

                                <Field type="radio" name="scan_setting_id" value="3" id="orderScanTypeLabel2" checked={this.state.formData.scan_setting_id === '3'} onChange={this.handleInputChange} />
                                <Label className="form-check-label" for="orderScanTypeLabel2">Serial Number</Label>
                              </div>
                            </Col>
                          </div>
                        }
                      </Row>
                    </CardBody>
                  </Card> */}
                </Col>

                <Col xs="12" xl="6" lg="12">
                  {/* <Card className="mt-3">
                    <CardTitle><h4>Handling Details</h4></CardTitle>
                    <CardBody className="pt-0">
                      <Row>
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Handling Fee Type*
                          </Label>
                          <Select className="form-input" name="handling_fee_type_id" options={handlingFeeTypeOptions} onChange={this.handleFeeChanged} value={{label: this.state.formData.handling_fee_type, value: this.state.formData.handling_fee_type_id}}  />
                           {
                            errors.handling_fee_type_id && touched.handling_fee_type_id ? (
                              <div className="invalid-feedback d-block">
                              {errors.handling_fee_type_id}
                              </div>
                              ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Handling Fee In*
                          </Label>
                          <Field className="form-control form-input" name="handling_fee_in" value={this.state.formData.handling_fee_in || ''} onChange={this.handleInputChange} />
                          {
                            errors.handling_fee_in && touched.handling_fee_in ? (
                            <div className="invalid-feedback d-block">
                            {errors.handling_fee_in}
                            </div>
                            ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Handling Fee Out*
                          </Label>
                          <Field className="form-control form-input" name="handling_fee_out" value={this.state.formData.handling_fee_out || ''} onChange={this.handleInputChange} />
                          {
                            errors.handling_fee_out && touched.handling_fee_out ? (
                            <div className="invalid-feedback d-block">
                            {errors.handling_fee_out}
                            </div>
                            ) : null
                          }
                        </Col>

                        {this.state.showFeeFields && 
                          <div className="clearfix w-100">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Handling Volume Limit*
                              </Label>
                              <Field className="form-control form-input" name="volume_limit" value={this.state.formData.volume_limit || ''} onChange={this.handleInputChange} />
                              {
                                errors.volume_limit && touched.volume_limit ? (
                                <div className="invalid-feedback d-block">
                                {errors.volume_limit}
                                </div>
                                ) : null
                              }
                            </Col>

                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Handling Fee Over Limit*
                              </Label>
                              <Field className="form-control form-input" name="volume_over_limit_fee" value={this.state.formData.volume_over_limit_fee || ''} onChange={this.handleInputChange} />
                              {
                                errors.volume_over_limit_fee && touched.volume_over_limit_fee ? (
                                <div className="invalid-feedback d-block">
                                {errors.volume_over_limit_fee}
                                </div>
                                ) : null
                              }
                            </Col>
                          </div>
                        }

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Rental Fee Type*
                          </Label>
                          {
                            errors.rental_fee_type_id && touched.rental_fee_type_id ? (
                              <div className="invalid-feedback d-block">
                              {errors.rental_fee_type_id}
                              </div>
                              ) : null
                          }
                          <div className="form-check form-input">
                            <Field type="radio" name="rental_fee_type_id" value="4" id="rentalTypeLabel1" checked={this.state.formData.rental_fee_type_id === "4"} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="rentalTypeLabel1">Weekly</Label>

                            <Field type="radio" name="rental_fee_type_id" value="5" id="rentalTypeLabel2" checked={this.state.formData.rental_fee_type_id === "5"} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="rentalTypeLabel2">Monthly</Label>

                            <Field type="radio" name="rental_fee_type_id" value="6" id="rentalTypeLabel3" checked={this.state.formData.rental_fee_type_id === "6"} onChange={this.handleInputChange} />
                            <Label className="form-check-label ml-0" for="rentalTypeLabel3">Fixed</Label>
                          </div>
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Rental Fee*
                          </Label>
                          <Field className="form-control form-input" name="rental_fee" value={this.state.formData.rental_fee || ''} onChange={this.handleInputChange} />
                          {
                            errors.rental_fee && touched.rental_fee ? (
                              <div className="invalid-feedback d-block">
                              {errors.rental_fee}
                              </div>
                              ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Email Alert Type*
                          </Label>
                          {
                            errors.email_alert_type_id && touched.email_alert_type_id ? (
                              <div className="invalid-feedback d-block">
                              {errors.email_alert_type_id}
                              </div>
                              ) : null
                          }
                          <Select className="form-input" name="email_alert_type_id" options={alertTypeOptions} onChange={(e) => this.handleSelectChange(e, 'email_alert_type_id')} value={{label: this.state.formData.email_alert_type, value: this.state.formData.email_alert_type_id}}  />
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Minimum Charge*
                          </Label>
                          <Field className="form-control form-input" name="minimum_charge" value={this.state.formData.minimum_charge || ''} onChange={this.handleInputChange} />
                          {
                            errors.minimum_charge && touched.minimum_charge ? (
                              <div className="invalid-feedback d-block">
                              {errors.minimum_charge}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card> */}

                  <Card className="mt-3">
                    <CardTitle><h4>Administrator Account</h4></CardTitle>
                    <CardBody className="pt-0">
                      <Row>
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            First Name*
                          </Label>
                          {this.state.emailCheck =='' || this.state.emailCheck == null?
                           <Field className="form-control form-input" name="admin_first_name" value={this.state.formData.admin_first_name || ''} onChange={this.handleInputChange} />
                            :
                           <Field className="form-control form-input" name="admin_first_name" value={this.state.formData.admin_first_name || ''} onChange={this.handleInputChange} disabled/>
                          }
                          {
                            errors.admin_first_name && touched.admin_first_name ? (
                              <div className="invalid-feedback d-block">
                              {errors.admin_first_name}
                              </div>
                              ) : null
                          }
                        </Col>

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Last Name
                          </Label>
                          {this.state.emailCheck =='' || this.state.emailCheck == null?
                          <Field className="form-control form-input" name="admin_last_name" value={this.state.formData.admin_last_name || ''} onChange={this.handleInputChange} />
                          :
                          <Field className="form-control form-input" name="admin_last_name" value={this.state.formData.admin_last_name || ''} onChange={this.handleInputChange} disabled/>
                          }
                          {
                            errors.admin_last_name && touched.admin_last_name ? (
                              <div className="invalid-feedback d-block">
                              {errors.admin_last_name}
                              </div>
                              ) : null
                          }
                        </Col>

                        {/* <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Password*
                          </Label>
                          <Field className="form-control form-input" name="admin_password" type="password" value={this.state.formData.admin_password || ''} onChange={this.handleInputChange} />
                          {
                            errors.admin_password && touched.admin_password ? (
                              <div className="invalid-feedback d-block">
                              {errors.admin_password}
                              </div>
                              ) : null
                          }
                        </Col> */}
                        {/* 
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Confirm Password*
                          </Label>
                          <Field className="form-control form-input" type="password" name="admin_confirm_password" value={this.state.formData.admin_confirm_password} onChange={this.handleInputChange} />
                          {
                            errors.admin_confirm_password && touched.admin_confirm_password ? (
                              <div className="invalid-feedback d-block">
                              {errors.admin_confirm_password}
                              </div>
                              ) : null
                          }
                        </Col> */}

                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Email Address*
                          </Label>
                          {this.state.emailCheck =='' || this.state.emailCheck == null?
                          <Field className="form-control form-input" type="email" name="admin_email" value={this.state.formData.admin_email || ''} 
                          onChange={ e => {
                            this.handleInputChange(e)
                            if (e.target.value && apiUtil.emailValidation(e.target.value)) {
                              this.searchAdminAcc(e.target.value)
                            }
                            
                          }} 
                          />
                          :
                          <Field className="form-control form-input" type="email" name="admin_email" value={this.state.formData.admin_email || ''} onChange={this.handleInputChange} disabled/>
                          }
                          {/* <Field className="form-control form-input" type="email" name="admin_email" value={this.state.formData.admin_email || ''} onChange={this.handleInputChange} /> */}
                          {
                            errors.admin_email && touched.admin_email ? (
                              <div className="invalid-feedback d-block">
                              {errors.admin_email}
                              </div>
                              ) : null
                          }
                          {
                            this.state.admin_email_validated &&  this.state.admin_email_err ? (
                              <div className="invalid-feedback d-block">
                              {this.state.admin_email_err}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="mt-3">
                    <h1>Company Departments</h1>
                    <hr />
                    {this.state.formData.departments.map(function (data, index) {
                    if (data.delete === false) {
                      return <DepartmentForm 
                              index={index}
                              name={data.department_name} 
                              contacts={data.contact_persons} 
                              key={index} 
                              handleremove={theClass.handleRemoveDepartment} 
                              handleinputchange={theClass.handleDepartmentInputChange}
                              addContactToState={theClass.addContactToState} 
                              deleteContactToState={theClass.deleteContactToState} 
                              />
                          } else return <div key={index}></div>;
                        })}
                  
                        <Box className="custom-button-component float-right">
                          <Button
                          type="button"
                          className="btn-department"
                          onClick={this.addDepartment}>
                            <Box pl={2} pr={2}>+ Add Department</Box>
                          </Button>
                        </Box>
                  </Card>
                </Col>
                <Col>
                
                </Col>

                <Col xs="12" className="mt-4 mb-5">
                  <Box clone>
                    <Grid item xs={'auto'}>
                      <ProgressButton
                      className="float-left" 
                      type={'submit'}
                      color={'secondary'}
                      isLoading={this.state.isLoading}>
                        <Box pl={2} pr={2}>Submit</Box>
                      </ProgressButton>
                    </Grid>
                  </Box>
                </Col>
              </Row>
            </Form>
          )}
          </Formik>
        </div>
      </Box>
    );
  }
}

export default CustomerForm;