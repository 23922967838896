import React, { Component } from "react";

import { 
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import MUIDataTable from "mui-datatables";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

import Select from 'react-select';
import moment from 'moment';
import { Row, Label, Container, InputGroup, InputGroupButtonDropdown, DropdownToggle,DropdownMenu, DropdownItem, Input, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import balanceEnquiryApi from "../../api/BalanceEnquiry";
import { locationData } from '../../data/misc';

class BalanceEnquiryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 50,
        sortOrder: {}
      },
      data: [],
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        id: '',
        name: ''
      },
      isFilterOpen: false,
      filter: {
        customerId: "null",
        text: 'Choose..',
        field: 'query',
        value: ''
      }
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  getData = async (customerId = null) => {
    if (!customerId) {
      if (!this.state.selectedCustomer.id) {
        // apiUtil.toast('No customer selected.', 'error');
        // return false;
        customerId= "";
      }else{
        customerId = this.state.selectedCustomer.id;
      }
    }

    this.setState({ isLoading: true });

    let params = {
      enquiry: "false",
      customer_id: customerId,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value
    };

    await balanceEnquiryApi.get(params).then(data => {

      data.data.map(function(vv,kk){
        locationData.map(function(v,k){ if(v.value==vv.location){ vv.location = v.label;} });
      });
      

      this.setState({
        isLoading: false,
        data: data.data
      });
    }).catch(error => console.log(error));
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

    changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }


  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name
      }
    });

    this.getData(event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;
    
    this.setState({
      filter: filter
    });
  }

  componentDidMount = () => {
    document.title = "Balance Enquiry All | WMS";
    window.scrollTo(0, 0);
    this.getCustomer();
    this.getData();
  }

  render() {
    const columns = [
      {
        name: "part_no",
        label: "Part No"
      },
      {
        name: "lot_no",
        label: "Lot No"
      },
      {
        name: "serial_no",
        label: "Serial No"
      },
      {
        name: "length",
        label: "L"
      },
      {
        name: "width",
        label: "W"
      },
      {
        name: "height",
        label: "H"
      },
      {
        name: "expiry_date",
        label: "Expiry Date",
        options: {
          customBodyRender: (value) => {
            return value?moment(value).format('DD-MMM-YYYY'):'';
          }
        }
      },
      {
        name: "container_receipt",
        label: "CR/PO/SO"
      },
      {
        name: "location",
        label: "Location"
      },
      {
        name: "created_at",
        label: "Date In",
        options: {
          customBodyRender: value => moment(value).format('DD-MMM-YYYY')
        }
      },
      {
        name: "balance",
        label: "Bal"
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      download: false,
      serverSide: false,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          default:
            console.log('action not handled.');
        }
      },
       onDownload: (buildHead, buildBody, columns, data) => {
          data.map(function(item, index){             
            if(item.data[3]){ item.data[3] = moment(item.data[3]).format('DD-MMM-YYYY');}
            item.data[6] = moment(item.data[6]).format('DD-MMM-YYYY');
          });
          return "\uFEFF" + buildHead(columns) + buildBody(data); 
      },
      downloadOptions:{filename: 'Balance Enquiry All - '+this.state.selectedCustomer.name+'.csv', separator: ','}
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <Container>
        <BreadCrumb />
        <h1 className="page-title">Balance Enquiry All</h1>
        </Container>
      </StickyPageHeader>

      <Card>
        <CardBody>
          <Row>
            <Col md={{size: 6, offset: 2}}>
              <div className="clearfix">
                <Label className="control-label">
                  Choose Company Code*
                </Label>
                <Select className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Search By
                </Label>
                <InputGroup className="form-input-group no-border">
                  <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.isFilterOpen} toggle={this.toggleFilter}>
                  <DropdownToggle caret>{this.state.filter.text}</DropdownToggle>
                  <DropdownMenu>
                  <DropdownItem value="query" onClick={this.handleFilterChange}>Choose..</DropdownItem>
                  <DropdownItem value="lot_no" onClick={this.handleFilterChange}>Lot No</DropdownItem>
                  <DropdownItem value="serial_no" onClick={this.handleFilterChange}>Serial No</DropdownItem>
                  <DropdownItem value="part_no" onClick={this.handleFilterChange}>Part No</DropdownItem>
                  </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <Input placeholder="optional.." onChange={this.handleFilterFieldChange} />
                </InputGroup>

                <Button
                  className="btn-theme form-input-btn"
                  onClick={() => this.getData()} 
                  color="primary"
                  variant="contained">Search</Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      
      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    Balance Enquiry (All)
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Box>;
  }
}

export default BalanceEnquiryList;