export class RoleAccessService {

    constructor() { }
    
    getCustomerTemplateRoleMenuList(){
      return this.customer_template_role_menu_list;
    }
    getCustomerTemplateRoleJobAccessList(){
      return this.customer_template_role_menu_list;
    }
    
    //  Get Uqique role permission  list
    getUniqueRolePermissionList(type) {
      let uniqueRolePermissionsRaw = window.localStorage.getItem('uniqueRolePermissions');
      uniqueRolePermissionsRaw = JSON.parse(uniqueRolePermissionsRaw)    
      let uniqueRolePermissionList = uniqueRolePermissionsRaw ? [...new Set(uniqueRolePermissionsRaw.map(item => item[type]))] : [];
      return uniqueRolePermissionList;
    }


    isPermissionActionAllowed(role, action) {
      const permissionAction = this.getUniqueRolePermissionList('name')
      if(!this.admin_roles_with_customer.includes(role) && permissionAction.includes(action)) {
        return true;
      } else if (this.admin_roles_with_customer.includes(role)) {
        return true;
      }
      return false;
    }

    // Permissions enchancement for new role
    getUniqueRolePermission() {
            
      let uniqueRolePermissions = this.getUniqueRolePermissionList('group');
      let uniqueRolePermissionAction = this.getUniqueRolePermissionList('name')


      if(uniqueRolePermissions.includes('Items') || uniqueRolePermissions.includes('Item Movement')) {
          uniqueRolePermissions.push('Inventory');
      }

      if(uniqueRolePermissionAction.includes('Item Report') || uniqueRolePermissionAction.includes('Outbound Order Report')) {
        uniqueRolePermissions.push('General Report');
      }

      return uniqueRolePermissions;

      
    }

    customer_template_role_menu_list = ["jobs", "user_profile", "job_form","job_schedule","job_schedule_list", "password" ];
    customer_template_role_job_denied_list = ["job_status", "job_assign", "dashboard", "job_date", "job_time", "step_status", "step_address", "add_more_job_step", "password"  ];
    admin_roles = ['Admin Template Roles', 'LDS Template Role', 'Super Admin Role' , "password" ];    
    admin_roles_with_customer = ['Admin Template Roles', 'LDS Template Role', 'Super Admin Role','Customer Template Role', "password" ];    
    
    isShouldShowMenu(currentRole, menu_name) { 
      const uniqueRolePermissions = this.getUniqueRolePermission();

      if(currentRole === 'Customer Template Role' && this.customer_template_role_menu_list.includes(menu_name)) {
        
        return true;
      }
      if(!this.admin_roles_with_customer.includes(currentRole) && uniqueRolePermissions.includes(menu_name)) {
        return true;
      } else if(this.admin_roles.includes(currentRole)) {
        return true;
      }
  
      return false;
    }  
    isShouldShowJobAccess (currentRole, section_name) {
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
        return false;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
      return true;
    }
    isShouldReadyOnlyAccess (currentRole, section_name) {
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
        return true;
      } else if(currentRole !== 'Customer Template Role') {
        return false;
      }
      return true;
    }
    isShouldShowEditButton (currentRole, section_name, worker_id) {
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name) &&  worker_id != null) {
        return false;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
      return true;
    }
}
