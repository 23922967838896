import React, { Component } from "react";
import { AuthContext } from "../../components/Auth/AuthDataProvider";
import { 
  Box,
  Grid,
  CircularProgress,
  Typography,
  Container
} from '@material-ui/core';

import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import axios from 'axios';
import { Card, CardBody, Row, Col } from "reactstrap";
import moment from 'moment';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
const { 
  REACT_APP_WMS_API_URL
} = process.env;
class CustomerList extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props);

    this.state = {
      tableState: {
        page: 1,
        rowsPerPage: 10,
        sortOrder: {},
        search: ''
      },
      data: [],
      total: 0,
      isLoading: false
    };
  }

  componentDidMount () {
    document.title = "All Customers | WMS";
    window.scrollTo(0, 0);
    this.getData();
  }

  getData = async () => {
    this.setState({ isLoading: true });
    const { accessToken } = this.context;
    let params = {
      page: this.state.tableState.page,
      take: this.state.tableState.rowsPerPage,
    };

    if (this.state.tableState.search !== '') {
      params.search = this.state.tableState.search;
    }

    await customerApi.get(params, accessToken).then(data => {
      if (data.errorMessage !== '') {
        apiUtil.toast(data.errorMessage, 'error');
      } else {
        this.setState({
          isLoading: false,
          data: data.data,
          total: data.total
        }, ()=> {
          // console.log('ads', this.state.data)
        });
      }
    }).catch(error => console.log(error));
  }

  changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  removeDialog = () => {
    return <RemoveDialog
    modal={this.state.modal}
    onRemove={(isRemove) => {
      if (isRemove) {
        let data_ = this.state.data.filter(r => r.id !== this.state.modalItem);
        let errorMessage = "";
        let status = 0;
    
        const URL = REACT_APP_WMS_API_URL + "admin/api/customers/" + this.state.modalItem;
        const access_token = localStorage.getItem("access_token");
        let data={ 
          destroy_all: true
        }
        axios.delete(
          URL,
          {
            data: data,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${access_token}`
            }
          }).then(data => {
            if (data.data.status === true) {
              apiUtil.toast(data.data.result, 'success');
            } else {
              apiUtil.toast(data.data.result, 'warning');
              console.log('eror', data.data);
            }
          })
        .catch( error => {
          status = error.response.data.status;
          errorMessage = error.response.data.error;
          apiUtil.toast(errorMessage, 'error');
        });

        this.setState({
          data: data_
        });
      }

      this.setState({
        modal: false,
        modalItem: null
      });
    }}
    />
  }

  deleteData = id => {
    this.setState({
      modal: true,
      modalItem: id
    });
  }
 
    /* EXPORT API */
    callExportPDFApi = () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
            let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
            let pdf = new jsPDF("p", "mm", "a4");
            var col = ["Company Name","Code", "Address", "Contact", "Contact Person"];
            var rows = [];
  
             let data = this.state.data;
              console.log('data', data)
             data.forEach(element => {      
              var temp = [element.company_name, element.company_code,element.address, element.contact_no1, element.customer_addresses[0].contact_name];
              rows.push(temp);
      
          });        
      
              pdf.autoTable(col, rows, { 
                // startY: 10,
                margin: { horizontal: 10 },
                styles: { overflow: "linebreak" },
                bodyStyles: { valign: "top" },
                columnStyles: { email: { columnWidth: "wrap" } },
                theme: "striped",
                // showHead: "everyPage",
                didDrawPage: function (data) {
                  // Header
                  pdf.setFontSize(16);
                  pdf.setTextColor("#161C22");
          
                  // Footer
                  let str = "" + pdf.internal.getNumberOfPages();
                  pdf.setFontSize(10);
          
                  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                  let pageSize = pdf.internal.pageSize;
                  let pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                  // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                  pdf.text(575, 830, str);
                } });
  
              pdf.save(`Customers ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
    }
    /* END EXPORT API */


  render() {
    const columns = [
      
      {
        name: "id",
        label: "Company Name",
        options: {
          customBodyRender: (id) => {
            let data = this.state.data.filter(dd => dd.id == id)
            return <a href={'./detail-customer?id=' + id}>
              {data[0].company_name}
            </a>
          }
        }
      },
      {
        name: "company_code",
        label: "Code"
      },
      {
        name: "address",
        label: "Address"
      },
      {
        name: "contact_no1",
        label: "Contact"
      },
      {
        name: "id",
        label: "Contact Person",
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.id == value)
            return data[0].customer_addresses.length > 0 ? data[0].customer_addresses[0].contact_name : ''
          }
        }
      },
      {
        name: "id",
        label: "Actions",
        options: {
          customBodyRender: (id, data) => {
            return (
              <div className="btn-group">
                <a href={'./edit-customer?id=' + id}>
                  <EditIcon className="fix-icon-color" />
                </a>
                <button type="button" onClick={() => this.deleteData(id)}>
                  <DeleteIcon />
                </button>
              </div>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      // filter: false,
      download: false,
      // print: false,
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      responsive: "standard", // standard | vertical | simple
      serverSide: true,
      count: this.state.total,
      customSearchRender: debounceSearchRender(500),
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page + 1, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page + 1, tableState.sortOrder);
            break;
          case 'search':
            this.search(tableState.page + 1, tableState.searchText);
            break;
          default:
            console.log('action not handled.');
        }
      }
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
      <Container>
      <Row className="d-flex align-items-center">
          <Col xs={12} lg={6}>
            <BreadCrumb />
            <h1 className="page-title">Customer List</h1>
        </Col>
       
        <Col xs={12} lg={6}>
        <div className='d-flex justify-content-end align-items-center pe-4' >
        <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create-customer')} />
        <Box ml={1}>
            <label class="dropdown me-3 py-2">
              <div class="dd-button-rr secondary">
              <MenuIcon width="26px" height="auto" />
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul class="dd-menu">
                  {/* <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li> */}
                  <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
              </ul>
            </label>
            </Box>
            </div>
        </Col>
        </Row>
      </Container>
        
      </StickyPageHeader>
      
      <Card>
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
              className={'customer-list'}
                title={
                  <Typography variant="h6">
                    All Customers
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.removeDialog()}
    </Box>;
  }
}

export default CustomerList;