import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider";
import Footer from '../../views/Footer/Footer.jsx'
import { RoleAccessService } from '../../data/role-access';
import { 
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons'

import logoTextWhite from '../../assets/img/logo_text_white.png';
import { ReactComponent as DashboardIcon } from '../../assets/img/left_menu/dashboard.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as CustomersIcon } from '../../assets/img/left_menu/customers.svg';
import { ReactComponent as ReportIcon } from '../../assets/img/left_menu/report.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/img/left_menu/resources.svg';
import { ReactComponent as WarehouseIcon } from '../../assets/img/left_menu/warehouse.svg';

import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import WMSIcon from '../../assets/img/services-icon1.png';
import LDSIcon from '../../assets/img/services-icon2.png';

const CustomMenuItem = (props) => {
  let {text, icon, url, active, items} = props;

  let [open, setOpen] = React.useState(active);

  return <React.Fragment>
    <ListItem
      className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
    >
      <a href={(items && items.length > 0) ? '' : url}
        onClick={(e) => {
          if(items && items.length > 0){
            e.preventDefault();
            e.stopPropagation();

            setOpen(!open);
          }
        }}
      >
        {icon ? icon : <span className={'no-icon'}></span>}
        <span>{text}</span>
        {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
      </a>
    </ListItem>
    {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={'sub-nav'} component="div" disablePadding>
        {items.map((item, i) => {
          return <React.Fragment key={i}>
            {item}
          </React.Fragment>;
        })}
      </List>
    </Collapse>}
  </React.Fragment>
}


class LeftMenu extends Component {
  static contextType = AuthContext;

  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  }

  render() {
    const { isCustomerRequired, currentRole, appPermission } = this.context;
    let grantedMenu = new RoleAccessService();
    return <Box>
      <div style={{minHeight:"92vh"}}>
      <List component="nav">
        <div className={this.props.className + ' custom-logo'}>
          <a href={'/'}><img src={logoTextWhite} alt={'intel'} width={'100%'} /></a>
        </div>

        <div id="servicesMenuSelector">
          <label>Services</label>
          <Select
            value='#'
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            onChange={(e) => this.handleServiceChange(e)}
          >
            <MenuItem style={{width: 210}} className="dark-select" value={'#'}><img src={WMSIcon} className="service-icon" alt="WMS Icon" />Warehouse Management System</MenuItem>
            <MenuItem style={{width: 210}} className="dark-select" value={'https://fms.evfy.sg/'}><img src={LDSIcon} className="service-icon" alt="LDS Icon" />Fleet Management System</MenuItem>
          </Select>
        </div>

        <hr />
        {(grantedMenu.isShouldShowMenu(currentRole, "Dashboard")) && <CustomMenuItem
              text={'Dashboard'}
              url={'/dashboard'}
              icon={<DashboardIcon />}
              active={this.props.location.pathname == '/dashboard'}
            />}
           {(grantedMenu.isPermissionActionAllowed(currentRole, "Customer Browse")) && <CustomMenuItem
                icon={<CustomersIcon />}
                text={'Customer'}
                url={'/customers/all-customers'}
                active={this.props.location.pathname == '/customers/all-customers'}
            />}

        {(grantedMenu.isShouldShowMenu(currentRole, "Inventory")) && <CustomMenuItem
          text={'Inventory'}
          icon={<FontAwesomeIcon icon={faBoxesStacked}/>}
          active={this.props.location.pathname.includes("/items")}
          items={[
            (grantedMenu.isShouldShowMenu(currentRole, "Item List"))?<CustomMenuItem
              text={'Items'}
              url={'/items/all-items'}
              active={this.props.location.pathname == '/items/all-items'}
            />:'',
            // (grantedMenu.isShouldShowMenu(currentRole, "Item Movement"))? <CustomMenuItem
            //   text={'Movement Log'}
            //   url={'/items/item-movements'}
            //   active={this.props.location.pathname === '/items/item-movements'}
            // />:'',
            (grantedMenu.isPermissionActionAllowed(currentRole, "Balance Enquiry All List"))?<CustomMenuItem
            text={'Balance Enquiry'}
            url={'/items/balance-enquiry/list'}
            active={this.props.location.pathname === "/items/balance-enquiry/list"}
          />:'',
          // (grantedMenu.isPermissionActionAllowed(currentRole, "Balance Enquiry All List"))? <CustomMenuItem
          //   text={'Balance Enquiry All'}
          //   url={'/items/balance-enquiry/all'}
          //   active={this.props.location.pathname === "/items/balance-enquiry/all"}
          // />:''
        ]}
        /> }
        <CustomMenuItem
          text={'Item Movement'}
          icon={<ResourcesIcon />}
          active={this.props.location.pathname.includes("/sales-orders") || this.props.location.pathname.includes("/inbound-orders") ||  this.props.location.pathname.includes("/sale-return")}
          items={[
            (grantedMenu.isShouldShowMenu(currentRole, "Outbound Orders"))?<CustomMenuItem
            text={'Inbound Order'}
            url={'/inbound-orders'}
            active={this.props.location.pathname === "/inbound-orders"}
          />:'',
            (grantedMenu.isShouldShowMenu(currentRole, "Outbound Orders"))?<CustomMenuItem
              text={'Outbound Order'}
              url={'/sales-orders'}
              active={this.props.location.pathname === "/sales-orders"}
            />:'',
            // <CustomMenuItem
            //   text={'Delivery Order'}
            //   url={'/transaction/delivery-order'}
            //   active={this.props.location.pathname === "/transaction/delivery-order"}
            // />,
            // <CustomMenuItem
            //   text={'Check Delivery Order Out'}
            //   url={'/transaction/delivery-order/check'}
            //   active={this.props.location.pathname === "/transaction/delivery-order/check"}
            // />,
            
            (grantedMenu.isPermissionActionAllowed(currentRole, "Change Outbound Order Status"))?<CustomMenuItem
            text={'Outbound Return'}
            url={'/sale-return'}
            active={this.props.location.pathname === "sale-return/all"}
          />:'',
            // <CustomMenuItem
            //   text={'Self Collection'}
            //   url={'/transaction/self-collection'}
            //   active={this.props.location.pathname === "/transaction/self-collection"}
            // />,
            // <CustomMenuItem
            //   text={'Balance Enquiry'}
            //   url={'/transaction/balance-enquiry/list'}
            //   active={this.props.location.pathname === "/transaction/balance-enquiry/list"}
            // />,
            // <CustomMenuItem
            //   text={'Balance Enquiry All'}
            //   url={'/transaction/balance-enquiry/all'}
            //   active={this.props.location.pathname === "/transaction/balance-enquiry/all"}
            // />
          ]}
        />

        {(grantedMenu.isShouldShowMenu(currentRole, "General Report")) && <CustomMenuItem
          text={'Report'}
          icon={<ReportIcon />}
          active={this.props.location.pathname.includes("/report")}
          url={'/report/sales'}
          // items={[
            // <CustomMenuItem
            //   text={'Rental Report'}
            //   url={'/report/in-out'}
            //   active={this.props.location.pathname === "/report/in-out"}
            // />,
            // <CustomMenuItem
            //   text={'Monthly Summary Report'}
            //   url={'/report/monthly-summary'}
            //   active={this.props.location.pathname === "/report/monthly-summary"}
            // />,
          //   (grantedMenu.isShouldShowMenu(currentRole, "General Report"))?<CustomMenuItem
          //     text={'General Report'}
          //     url={'/report/sales'}
          //     active={this.props.location.pathname === "/report/sales"}
          //   />:'',
          //   <CustomMenuItem
          //     text={'Aging Report'}
          //     url={'/report/aging'}
          //     active={this.props.location.pathname === "/report/aging"}
          //   />
          // ]}
        />}
        {(grantedMenu.isShouldShowMenu(currentRole, "Warehouse")) && <CustomMenuItem
          text={'Warehouse'}
          url={'/warehouse'}
          icon={<WarehouseIcon width="14px" height="auto" />}
          active={this.props.location.pathname.includes('/warehouses') || this.props.location.pathname.includes('/warehouse')}
        />}
      </List>
      </div>
      <Footer />
    </Box>
  }
}

export default withRouter(LeftMenu);