import axios from 'axios';

const { 
  REACT_APP_WMS_API_URL
} = process.env;

const balanceEnquiryApi = {
	get: async (request, accessToken = localStorage.getItem("access_token")) => {
    console.log(request);
		let errorMessage = "";
    let params = "enquiry=" + request.enquiry + "&customer_id=" + request.customer_id + "&warehouse_id=" + request.warehouse_id;


    if (request.optional_filter_by === "lot_no") {
      params += "&lot_no=" + request.optional_query;
    }
    if (request.start_date) {
      params += "&start_date=" + request.start_date;
    }
    if (request.end_date) {
      params += "&end_date=" + request.end_date;
    }

    if (request.optional_filter_by === "part_no") {
      params += "&part_no=" + request.optional_query;
    }

    if (request.optional_filter_by === "serial_no") {
      params += "&serial_no=" + request.optional_query;
    }

    // const URL = REACT_APP_WMS_API_URL + "admin/api/order_details?" + params;
    const URL = REACT_APP_WMS_API_URL + "admin/api/items?" + params;

    // let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      })
    .then(res => {
      if (res.status === 200 && res.data.result.length > 0) {
        for (let i = 0; i < res.data.result.length; i++) {
          result[i] = res.data.result[i];
        }
      }
    }).catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
	}
}

export default balanceEnquiryApi;