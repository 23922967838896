import React, { Component } from "react";
import { 
  Typography
} from '@material-ui/core';
import { Container, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";

class HandlingReport extends Component {
	constructor (props) {
		super(props);
		this.state = {
			customer: this.props.reportData.customer,
			total: parseFloat(this.props.reportData.grand_total_charges),
			title: this.props.reportData.result.Title,
			reportIn: this.props.reportData.result.Handling_In,
			reportOut: this.props.reportData.result.Handling_Out
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (nextProps.reportData.customer.id !== this.state.customer.id) {
			this.setState({
				customer: nextProps.reportData.customer,
				total: parseFloat(nextProps.reportData.grand_total_charges),
				title: nextProps.reportData.result.Title,
				reportIn: nextProps.reportData.result.Handling_In,
				reportOut: nextProps.reportData.result.Handling_Out
			});
		}
	}

	render () {
		const columns = [
      {
        name: "Date",
        label: "Date"
      },
      {
        name: "Ref_NO.",
        label: "Ref No."
      },
      {
        name: "CR/PO/SO",
        label: "CR/PO/SO"
      },
      {
        name: "Item_Description",
        label: "Item Description"
      },
      {
        name: "Part_No.",
        label: "Part No."
      },
      {
        name: "Quantity",
        label: "Quantity"
      },
      {
      	name: "Total_(m3)",
      	label: "Total(m3)",
        options: {
          customBodyRender: (value) => {
            return parseFloat(value).toFixed(6).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
        }
      },
      {
      	name: "Weight_(kg)",
      	label: "Weight(kg)"
      },
      {
      	name: "Charge_($)",
      	label: "Charge($)",
      	options: {
      		customBodyRender: (value) => {
      			return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      		}
      	}
      }
    ];

    const options = {
      selectableRows: 'none',
	  download: false,
      rowsPerPage: 10,
      serverSide: false
    }

    return (
    	<Container fluid={true}>
      <Row className="mt-3">
        <Col xs="12">
          <div className="text-center mt-3 mb-3">
            <h3>Grand Total</h3>
            <h2 className="font-weight-bold h2">{this.state.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h2>
          </div>
          
        	<h2 className="mb-3">{this.state.title}</h2>

        	<MUIDataTable
	        	title={
		        	<Typography variant="h6">
		        	Handling In
		        	</Typography>
		        }
        		data={this.state.reportIn}
        		columns={columns}
        		options={options}
        	/>

        	<MUIDataTable
        		title={
	        		<Typography variant="h6">
	        		Handling Out
	        		</Typography>
	        	}
        		className="mt-3"
        		data={this.state.reportOut}
        		columns={columns}
        		options={options}
        	/>
        	
        </Col>
      </Row>
      </Container>
    );
  }
}

export default HandlingReport;