import React, { Component } from 'react';

import { 
    Box,
    CircularProgress,
    Grid,
    Card,
    Container,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import Table from '../../components/Table/Table.jsx';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import queryString from 'query-string';
import moment from 'moment'
import { RoleAccessService } from '../../data/role-access.js';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}

class ItemDetail extends Component {
    constructor(props) {
        super(props)

        let grantedAccess = new RoleAccessService();
        this.state = {
            grantedAccess : grantedAccess,
            currentRole: window.localStorage.getItem('current_role') || null,
            data: '',
            isLoading: false,
            tabValue: 0,
            tempItemOrder: [{ 
                id : 1,
                date : '12-02-2022',
                sale_order : '0Q-A122',
                customer_name : 'cus',
                quantity : '1.00',
                price : 'SGD1.00',
                total : 'SGD2.00',
                status : 'void'
            }]
        }
    }
    
    componentDidMount() {
        let urlQuery = queryString.parse(window.location.search);
        if (urlQuery.id !== undefined) {
            this.setState({
              dataId: urlQuery.id
            });
            this.callDetailsApi(urlQuery.id, (data) => {
                if(data) {
                    this.setState({
                        data
                    })
                }
            })
        }
    }
    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
            isLoading: true
        }, () => {
            wmsApi.read('items/' + id, null, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback((data && data.result) ? data.result : null);
                    }
                });
                }, (error, type) => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(callback){
                    callback(null, []);
                    }
                });
                });
            });
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    }
    getAvailabelforSaleStock = (item) => {
        let HandStock = item.stock_on_hand
        let CommittedStock = item.committed_stock ?  parseInt(item.committed_stock) : 0
        return HandStock - CommittedStock
    }

    itemDetailPage = () => {
        return <>
        <Box>
            <Grid container xs={12}>
                <Grid item xs={8}>
                <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Client Name</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.customer && this.state.data.customer !== null && this.state.data.customer !== '' ? this.state.data.customer.company_name :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>SKU</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.sku && this.state.data.sku !== null ? this.state.data.sku :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Item Type</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.item_type && this.state.data.item_type !== null ? this.state.data.item_type :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Brand</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.brand && this.state.data.brand !== null ? this.state.data.brand :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Weight</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.weight && this.state.data.weight !== null && this.state.data.weight > 0 ? this.state.data.weight :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    {/* <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Brand</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.brand && this.state.data.brand !== null && this.state.data.brand !== '' ? this.state.data.brand :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid> */}
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Dimensions</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                        <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >
                                {this.state.data.dimensions && this.state.data.dimensions !== null ? 
                                `${JSON.parse(this.state.data.dimensions).key1 ? JSON.parse(this.state.data.dimensions).key1 :' '} ${ JSON.parse(this.state.data.dimensions).key1 && JSON.parse(this.state.data.dimensions).unit ? JSON.parse(this.state.data.dimensions).unit  :'' }  
                                 ${JSON.parse(this.state.data.dimensions).key2 ? (JSON.parse(this.state.data.dimensions).key1 ? ' x ': ' ') + JSON.parse(this.state.data.dimensions).key2 :' '} ${ JSON.parse(this.state.data.dimensions).key2 && JSON.parse(this.state.data.dimensions).unit ? JSON.parse(this.state.data.dimensions).unit  :''}
                                 ${JSON.parse(this.state.data.dimensions).key3 ? (JSON.parse(this.state.data.dimensions).key1 || JSON.parse(this.state.data.dimensions).key2? ' x ': ' ') + JSON.parse(this.state.data.dimensions).key3 :' '} ${ JSON.parse(this.state.data.dimensions).key3 && JSON.parse(this.state.data.dimensions).unit ? JSON.parse(this.state.data.dimensions).unit  :''}`
                                
                                :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Creation Date</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.created_at && this.state.data.created_at !== null ?  moment(this.state.data.created_at).format('DD-MMM-YYYY'):'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Expire Date</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.expire_date && this.state.data.expire_date !== null? moment(this.state.data.expire_date).format('DD-MMM-YYYY') :'-' } </Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    {/* <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Cost Price</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.cost_prices && this.state.data.cost_prices !== null &&  this.state.data.cost_prices > 0 ? this.state.data.cost_prices :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Selling Price</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.selling_prices && this.state.data.selling_prices !== null &&  this.state.data.selling_prices > 0 ? this.state.data.selling_prices :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid> */}
                    {/* <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Warehouse</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.warehouse_id && this.state.data.warehouse_id !== null &&  this.state.data.warehouse_id > 0 && this.state.data.warehouse ? this.state.data.warehouse.name :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid> */}
                    {/* <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Opening Stock</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.opening_stock && this.state.data.opening_stock !== null &&  this.state.data.opening_stock > 0 ? this.state.data.opening_stock :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Stock On Hand</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.opening_stock && this.state.data.stock_on_hand !== null &&  this.state.data.stock_on_hand > 0 ? this.state.data.stock_on_hand :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Committed Stock</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.committed_stock && this.state.data.committed_stock !== null &&  this.state.data.committed_stock > 0 ? this.state.data.committed_stock :'-' }</Typography>
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={4} className='item-Details'>
                            <Box mt={2}>
                            <Typography variant='body2'>Available for Sale</Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Box mt={2}>
                            <Typography variant='body2' style={{fontWeight:'900'}} >{this.state.data.opening_stock && this.state.data.stock_on_hand !== null &&  this.state.data.stock_on_hand > 0 ? this.getAvailabelforSaleStock(this.state.data) :'-' }</Typography>
                            </Box>
                            
                        </Grid>

                    </Grid> */}
                    {/* <Grid container xs={12} className="mt-4">
                        <Typography className='fw-bolder mb-2'>Stock Locations</Typography>
                        <table className="stock_table">
                            <tr>
                                <th rowspan="2">WAREHOUSE NAME</th>
                                <th colspan="3">ACCOUNTING STOCK</th>
                            </tr>
                            <tr>
                                <th>STOCK ON HAND</th>
                                <th>COMMITTED STOCK</th>
                                <th>AVAILABLE FOR SALE</th>
                            </tr>
                            <tr>
                                <td>{this.state.data.warehouse && this.state.data.warehouse.name && this.state.data.warehouse.name}</td>
                                <td>{this.state.data.stock_on_hand && this.state.data.stock_on_hand}</td>
                                <td>{this.state.data.committed_stock && this.state.data.committed_stock}</td>
                                <td>{this.state.data.opening_stock && this.state.data.stock_on_hand !== null &&  this.state.data.stock_on_hand > 0 ? this.getAvailabelforSaleStock(this.state.data) :'-' }</td>
                            </tr>
                        </table>
                    </Grid> */}
                </Grid>
                <Grid item xs={4}>
                    <Typography className="fs-5 fw-bold">Images</Typography>
                    <Box mt={5}>{this.importedImglist()}</Box>
                </Grid>
            </Grid>
            
        </Box>
        
        </>
    }

    itemHistory = () => {
        return <>
            <Box>
               
                {this.state.data && this.state.data.item_histories.length > 0 &&
                    <Grid container xs={12}>
                        <Grid container xs={12} className="bg-light">
                                <Grid item xs={4}><Box m={1}><Typography variant='subtitle1'>Date</Typography> </Box></Grid>
                                <Grid item xs={8}><Box m={1}><Typography variant='subtitle1'>Detail</Typography> </Box></Grid>
                        </Grid>
                        {this.state.data.item_histories.map(his => {
                            return <>
                            <Grid container xs={12} className="history-border">
                                <Grid item xs={4} ><Box m={1}><span>{moment(his.created_at).format('DD-MMM-YYYY HH:mm:ss')}</span></Box></Grid>
                                <Grid item xs={8}><Box m={1}><span>{his.change_log}.</span> <span style={{fontWeight:"600"}}>{his.history_note !== null ? his.history_note: '' }</span> <span style={{fontStyle:"italic"}}>by {his.admin_name}</span> </Box></Grid>
                            </Grid>
                            </>
                        })}
                     </Grid>
                }
              
            </Box>
        </>
    }

    
    transaction = () => {
        return <>
            <Box >
            <Table 
            ref={this.refTable}
            className={'custom-table-list'}
            isLoading={this.state.isLoading}
            grouping={false}
            selection={false}
            page={this.state.page}
            total={this.state.total}
            limit={this.state.limit}
            onPageChange={(page,limit) => {
                this.setState({
                limit:limit,
                page:page
                }, () => {
                this.callReadApi();
                });
                }}
            data={this.state.data.order_items}
            onSelectionChange={(selectedRows) => {
                // console.log("selectedRows", selectedRows);
            }}
            // Row={this.customRow}
            columns={[
                
                { 
                    title: "Date", 
                    field: "date",
                    render: (row, type) => {
                    return (
                        <div>
                        {moment(row.order.updated_at).format('DD-MMM-YYYY')}
                        </div>
                    );
                    }
                    
                },
                { 
                    title: "Outbound Order", 
                    field: "sale_order",
                    render: (row, type) => {
                        return (
                            <div>
                             <a href={'/sales-order/order-detail?id=' + row.order.id}>{row.order.reference_no}</a>
                            </div>
                        );
                    }
                },  
                { 
                    title: "Client Name", 
                    field: "customer_name",
                    render: (row, type) => {
                        return (
                            <div>
                            {row.order.customer.company_name}
                            </div>
                        );
                    }
                },
                { 
                    title: "Quantity Out", 
                    field: "quantity_sold",
                    render: (row, type) => {
                        return (
                            <div>
                            {row.item_qty}
                            </div>
                        );
                    }
                },
                // { 
                //     title: "PRICE", 
                //     field: "price",
                //     render: (row, type) => {
                //         return (
                //             <div>
                //             {this.state.data.selling_prices}
                //             </div>
                //         );
                //     }
                // },
                // { 
                //     title: "Total", 
                //     field: "total",
                //     render: (row, type) => {
                //         return (
                //             <div>
                //             {row.item_qty * this.state.data.selling_prices}
                //             </div>
                //         );
                //     }
                // },
                { 
                    title: "Status", 
                    field: "status",
                    render: (row, type) => {
                        if(row.order.order_status && row.order.order_status.status == 'Confirmed') {
                            return <div style={{color:'#AE0264', fontSize: '12px'}} className="fw-bold">CONFIRMED</div>
                          } else if (row.order.order_status && row.order.order_status.status == 'Void'){
                            return <div style={{color:'#EA5455', fontSize: '12px'}} className="fw-bold">VOID</div>
                          } else if (row.order.order_status && row.order.order_status.status == 'Returned'){
                            return <div style={{color:'#FF6600', fontSize: '12px'}} className="fw-bold">RETURNED</div>
                          }else if (row.order.order_status && row.order.order_status.status == 'Completed'){
                            return <div style={{color:'#5CB85C', fontSize: '12px'}} className="fw-bold">COMPLETED</div>
                          } else {
                            return ''
                          }
                      
                        }
                },
            ]}
            />
            </Box>
        </>
    }


    importedImglist = () => {
        return <>
        {this.state.data && this.state.data.item_images.length > 0 ?
        
        this.state.data.item_images.map((img)=>{
            
           return<img src={img.image_url} width="100px" height="auto" style={{margin:"5px"}}/>
        }) : "" }

        </>
    }
    getDetails = () => {
        return <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="basic tabs example">
          <Tab label="Item Details" {...a11yProps(0)} />
          <Tab label="Transactions" {...a11yProps(1)} />
          <Tab label="History" {...a11yProps(2)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={this.state.tabValue} index={0}>
        {this.itemDetailPage()}
      </TabPanel>
      <TabPanel value={this.state.tabValue} index={1}>
        {this.transaction()}
      </TabPanel>
      <TabPanel value={this.state.tabValue} index={2}>
        {this.itemHistory()}
      </TabPanel>
      {/* <TabPanel value={this.state.tabValue} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
        </>
    }
    render() {
        return <Box><StickyPageHeader>
        <Container>
                <BreadCrumb />
                <h1 className="page-title">Item Details</h1>
                {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Add") && <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} /> }
                {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Edit") && <IconButton className="btn-theme btn-theme-1" label="Edit " icon="edit" onClick={() => this.props.history.push('./edit?id='+this.state.dataId)} /> }
                {/* {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Item Movement Create") && <IconButton className="btn-theme btn-theme-1" label="Adjust" icon="edit" onClick={() => this.props.history.push('./movement-form?id='+this.state.dataId)} /> } */}
                </Container>
                </StickyPageHeader>
            <Container maxWidth="lg" >
                <Box mt={2}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Box mt={2} >
                   <Typography variant="h5"> {this.state.data.name}</Typography>
                   {this.getDetails()}
                  </Box>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                
            </Container>
      </Box>

    }
}

export default ItemDetail;