import axios from 'axios';

const { 
  REACT_APP_WMS_API_URL
} = process.env;

const customerApi = {
	get: async (params, accessToken = localStorage.getItem("access_token") ) => {
		let errorMessage = "";
    let total = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/customers?application_registration_ids=2"; //application_registration_ids=2

    // let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      })
    .then(res => {
      if (res.status === 200 && res.data.result.length > 0) {
        total = res.data.total;
        for (let i = 0; i < res.data.result.length; i++) {
          result[i] = res.data.result[i];
          if (res.data.result[i]['customer_addresses'][0] !== undefined) {
            result[i]['address'] = res.data.result[i]['customer_addresses'][0]['address_line1'];
          } else {
            result[i]['address'] = '';
          }
        }
      }
    }).catch( error => {
      errorMessage = error.message;
    });

    return { data: result, total: total, errorMessage: errorMessage };
	},

  show: async (id) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/customers/" + id;

    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.status;
      errorMessage = error.message;
    });

    return { data: getResult.data, errorMessage: errorMessage, status: status};
  },

  create: async (request) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/customers";

    let access_token = localStorage.getItem("access_token");

    const result = await axios.post(
      URL,
      request,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( (error) => {
      status = error.response.status;
      if (error.response.data.error !== undefined) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = "Something went wrong!";
      }
    });

    return { data: result, errorMessage: errorMessage, status: status};
  },

  update: async (id, request) => {
    let errorMessage = "";
    let status = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/customers/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.put(
      URL,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.status;
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage, status: status};
  },

  delete: async (id) => {
    let errorMessage = "";
    let status = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/customers/" + id;
    const access_token = localStorage.getItem("access_token");
    let data={ 
      destroy_all: true
    }
    const result = await axios.delete(
      URL,
      {
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
    .catch( error => {
      status = error.response.data.status;
      errorMessage = error.response.data.error;
    });

    return { data: result.data, errorMessage : errorMessage , status: status};
  }
}

export default customerApi;