import React, { Component } from "react";
import { 
  Typography
} from '@material-ui/core';
import { Container, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";

class RentalReport extends Component {
	constructor (props) {
		super(props);
		this.state = {
			customer: this.props.reportData.customer,
			total: parseFloat(this.props.reportData.grand_total_charges),
			reports: this.props.reportData.result
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (nextProps.reportData.customer.id !== this.state.customer.id) {
			this.setState({
				customer: nextProps.reportData.customer,
				total: parseFloat(nextProps.reportData.grand_total_charges),
				reports: nextProps.reportData.result
			});
		}
	}

	render () {
		const columns = [
      {
        name: "Date_In",
        label: "Date"
      },
      {
        name: "Ref_NO.",
        label: "Ref No."
      },
      {
        name: "CR/PO/SO",
        label: "CR/PO/SO"
      },
      {
        name: "Item_Description",
        label: "Item Description"
      },
      {
        name: "Part_No",
        label: "Part No."
      },
      {
        name: "Qty_Bal",
        label: "Qty Bal."
      },
      {
      	name: "Vol_(m3)",
      	label: "Vol(m3)",
        options: {
          customBodyRender: (value) => {
            return parseFloat(value).toFixed(6).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
        }
      },
      {
      	name: "Amount_($)",
      	label: "Amount($)",
      	options: {
      		customBodyRender: (value) => {
      			return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      		}
      	}
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 10,
      serverSide: false
    }

    return (
    	<Container fluid={true}>
      <Row className="mt-3">
        <Col xs="12">
          <div className="text-center mt-3 mb-3">
            <h3>Grand Total</h3>
            <h2 className="font-weight-bold h2">{this.state.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h2>
          </div>
          
        	<h2 className="mb-3">Rental Fee Report</h2>

          {this.state.reports.map(function (data, index) {
            return <MUIDataTable
              key={index}
              className="mt-3 mb-3"
              title={
                <Typography variant="h6">
                {data.Title}
                </Typography>
              }
              data={data.Data}
              columns={columns}
              options={options}
            />
          })}

        	
        </Col>
      </Row>
      </Container>
    );
  }
}

export default RentalReport;