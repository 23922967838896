import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider";
import apiUtil from '../../api/apiUtil';
import api from "../../api/api";

import { 
  Button,
  Menu,
  MenuItem,
  Box,
  Divider
} from '@material-ui/core';

import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import defaultImg from '../../assets/img/default_image.jpg';

class ProfileDropdown extends Component {
  static contextType = AuthContext;
  constructor(props){
    super(props);

    this.state = {
      profileMenu: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profileMenu !== this.state.profileMenu) {
      this.setState({
        profileMenu: nextProps.profileMenu
      });
    }
  }
 
componentDidMount () {
  const { accessToken } = this.context;
  api.read('application_company_settings', {}, accessToken)
  .then(result => {
      apiUtil.parseResult(result, data => {
          let company_id = data.result[0].application_company_id;    
          window.localStorage.setItem('company_id', company_id);

      })
  })
}
  getUserLogo = () => {
    const { user } = this.context;
    return apiUtil.getUserLogo(user, defaultImg);
  }

  getUserName = () => {
    const { user } = this.context;
    return apiUtil.getUserName(user, defaultImg);
  }

  // getUserEmail = () => {
  //   let userInfo = this.getUserinfo();
  //   return (userInfo && userInfo.email) ? userInfo.email : '';
  // }

  getCompanyID = () => {
    let company = window.localStorage.getItem('company_id');
    return company ? window.btoa( company ) : ''; //decript  window.atob( company )
  }

  render() {
    return (
      <Box textAlign="right" height="100%" ml={3} className="header-profile">
        <Button 
          aria-label="profile-menu" 
          aria-haspopup="true"
          onClick={(event) => {
            this.setState({
              profileMenu: event.currentTarget
            });
          }}>
          <img className="profile-pic-logo" src={this.getUserLogo()} alt="User" width="35" height="35" />
          <span>{this.getUserName()}</span>
          <ArrowDownIcon />
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={this.state.profileMenu}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(this.state.profileMenu)}
          onClose={() => {
            this.setState({
              profileMenu: null
            });
          }}>
             <div className="user-info">
              <img src={this.getUserLogo()} alt="User" 
                className="profile-pic-logo"
                width="35" height="35"/>
              <div className="user-info-right">
                <div className="user-info-name">{this.getUserName()}</div>
                {/* <div className="user-info-email">{this.getUserEmail()}</div> */}
              </div>
              
            </div>
            <Divider className="user-info-divider"/>
            <div className="user-info-2">
              {/* <img src={this.getUserLogo()} alt="User" 
                className="profile-pic-logo"
                width="35" height="35"/> */}
                 <div className="user-info-name">Organization ID</div>
              <div className="user-info-right">
                <div className="user-info-email">{this.getCompanyID()}</div>
              </div>
              
            </div>
            <Divider className="user-info-divider"/>
            {/* <CustomMenuItem
              text={'Change Password'}
              url={'/password'}
            /> */}
            <MenuItem >   
                  <Link to="/password" style={{color: '#565553'}}>
                    Change Password
                  </Link>
              </MenuItem>
              <Divider className="user-info-divider"/>
          <MenuItem 
          onClick={() => {
            if(this.props.onLogout)
              this.props.onLogout();
          }}>
          Logout
          </MenuItem>
        </Menu>
      </Box>
    );
  }
}

export default ProfileDropdown;
