
import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider";
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  Container,
  Divider,
  CircularProgress
} from '@material-ui/core';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';
import axios from 'axios';
import apiUtil from '../../api/apiUtil';
import IconButton from "../../components/Button/IconButton";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

import { Row, Card, CardBody, Col} from "reactstrap";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import moment from 'moment';
import Loading from "react-fullscreen-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPenToSquare, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { RoleAccessService } from '../../data/role-access';

class WareHouse extends Component {

  static contextType = AuthContext

  constructor(props){
    super(props);

    let grantedAccess = new RoleAccessService();
    this.state = {
      grantedAccess : grantedAccess,
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
      warehouseDialog: false,
      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,
      isLoading: false,
      isStatLoading: false,
      pageLoading: false,
    //   grouping: true,
      // limit: apiUtil.getDefaultPageSize(),
      updateId: ' ',
      name: '',
      nameValidated: false,
      location:'',
      locationValidated: false,
      address:'',
      addressValidated: false,
      contactNumber: '',
      postalCode: '',
      parentId:' ',
      limit: 10,
      data: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : []
    }
  }
  componentDidMount() {
    this.getWareHouse();
  }

  getWareHouse(id = 0) {
    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_WMS_API_URL
    } = process.env;

    this.setState({
        pageLoading: true
      });
      const PATH = 'admin/api/';
      const api = "warehouses";
      let URL = REACT_APP_WMS_API_URL + PATH + api;
      if(id != 0 ) {
        URL = REACT_APP_WMS_API_URL + PATH + api + '/' + id;
      }
    // let token = window.localStorage.getItem('access_token');
    let { accessToken } = this.context

    let param = {
        page: this.state.page,
        take: this.state.limit,
        search: "",
        // start_date: startDate,
        // end_date: endDate,
    };
    axios.get(
        // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
        URL,
        {
            params: param,
            data: param,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
        .catch(error => {
            try {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            } catch (err) {
                errorMessage = 'Service Unavailable!'
            }
            this.setState({
              pageLoading: false,
          });
        }).then((response) => {
            // console.log(response);
            if (response != null) {
              if(id != 0) {
                this.setState({
                  updateId: response.data.result.id,
                  data: response.data.result,
                  total: response.data.total,
                  name:response.data.result.name,
                  location:response.data.result.location,
                  address:response.data.result.address,
                  postalCode:response.data.result.postal_code,
                  contactNumber:response.data.result.contact_number,
                  parentId:response.data.result.parent_warehouses_id,
                  pageLoading: false,
              });
              this.getWareHouse();
              }
                this.setState({
                    data: response.data.result,
                    total: response.data.total,
                    pageLoading: false,
                });
            }

        });
}

callWareHouseDeleteApi = (id) => {

  let errorMessage = "";
  let status = 0;
 
  const {
    REACT_APP_WMS_API_URL
  } = process.env;

  this.setState({
      isLoading: true
    });

  const PATH = 'admin/api/';
  const api = "warehouses/";
  const URL = REACT_APP_WMS_API_URL + PATH + api + id;
  let token = window.localStorage.getItem('access_token');

  let data = {
  }
    axios.delete(
          URL,
          // data,
          { 
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              }
          })
          .catch( error => {
              try {
                  status = error.response.status;
                    if (error.response) {
                      // Request made and server responded
                      errorMessage = error.response.data.error;
                    } else if (error.request) {
                      // The request was made but no response was received
                      errorMessage = error.request;
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      errorMessage = error.message;
                    }
                    // console.log('error', error.response.data.error )
                    apiUtil.toast(error.response.data.error , 'error');
              } catch(err) {
                  errorMessage = 'Service Unavailable!'
                  console.log('err', err.response )
                  // apiUtil.toast(err.response.result.error , 'error');
              }
              this.setState({
                isLoading: false,
              });
          }).then((response) => {
            this.setState({
              isLoading: false,
              warehouseDialog: false,
            });
            if(response != null){
                apiUtil.toast('Successfully Deleted', 'success');
                this.getWareHouse();
            }
          });
}


customTable = () => {
  return <Container>
    <Row>
      {this.state.data && this.state.data.map((data) =>
      <Col md={6} lg={4} xs={12} className='p-2'>
        <a href={'/warehouse/warehouse-detail?id=' + data.id} style={{textDecoration: 'none'}}>
        <div  className="warehouse-card p-4 m-2 h-100" style={{backgroundColor: '#FFFFFF'}}>
        <div>
          <div className="d-flex justify-content-between align-item-center">
            <span className="fw-bold fs-5">{data.name}</span>
            <div>
            {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Warehouse Edit") && <a href={'/warehouse/edit?id=' + data.id} className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faPenToSquare} /></a>}
            {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Warehouse Delete") && <a href="#" onClick={() => this.deleteData(data.id)} className="btn btn-outline-danger btn-sm ms-3" style={{zIndex: '40'}}><FontAwesomeIcon icon={faTrashCan} /></a> }
            </div>
          </div>
          <Row className='row d-flex'>
          <Col md={6} xs={6}>
            <p className="fw-regular pt-4">{data.location == '' || data.location == null? '' : data.location}</p>
            <p className="fw-regular" >{data.contact_number == '' || data.contact_number == null? '' : data.contact_number}</p>
          </Col>
          {(data.latitude && data.latitude != null) || (data.longitude && data.longitude != null)?
          <Col md={6} xs={6} className="d-flex align-self-center justify-content-end"><a href={"http://maps.google.com/maps?q="+data.latitude+","+data.longitude+"("+data.name+")"} target="_blank" className="primary" style={{zIndex: '40'}}><FontAwesomeIcon icon={faLocationDot} size="2x"/></a></Col> : <Col md={6} xs={6} className="d-flex align-self-center justify-content-end"><a href="#" style={{color:"#cccccc"}}><FontAwesomeIcon icon={faLocationDot} size="2x" /></a></Col> }
          </Row>
      </div>
      </div>
      </a>
      </Col>
      )}
      
    </Row>
  </Container>
}


customDialog = () => {
  return <CustomDialog 
    open={this.state.openDialog}
    title={'Delete'}
    onClose={() => {
      this.setState({
        openDialog: false,
        openDialogItem: null,
      });
    }}
    onOk={() => {
      let row = this.state.openDialogItem;
      this.callWareHouseDeleteApi(row);
        this.setState({
          openDialog: false,
          openDialogItem: null,
        }, () => {
          this.getWareHouse();
        });
    }}
  >
    <Box>Are you sure you want to delete?</Box>
  </CustomDialog>
}

deleteData = id => {
  this.setState({
    openDialog: true,
    openDialogItem: id
  });
}

convertUnicode(input) {
  return input.replace(/\\u[0-9a-fA-F]{4}/g,function(a,b) {
    var charcode = parseInt(b,16);
    return String.fromCharCode(charcode);
  });
}

  /* EXPORT API */
  callExportPDFApi = () => {
    let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
          let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
          let pdf = new jsPDF("p", "mm", "a4");
          var col = ["Warehouse Name","Location", "Postal Code", "Contact Number", "Parent Warehouse"];
          var rows = [];

           let data = this.state.data;
            console.log('data', data)
           data.forEach(element => { 
            var temp = [element.name, this.convertUnicode(element.location), element.postal_code? element.postal_code :'' , element.contact_number, element.parent_warehouse? element.parent_warehouse.name :'' ];

            rows.push(temp);
    
        });        
    
            pdf.autoTable(col, rows, { 
              // startY: 10,
              margin: { horizontal: 10 },
              styles: { overflow: "linebreak" },
              bodyStyles: { valign: "top" },
              columnStyles: { email: { columnWidth: "wrap" } },
              theme: "striped",
              // showHead: "everyPage",
              didDrawPage: function (data) {
                // Header
                pdf.setFontSize(16);
                pdf.setTextColor("#161C22");
                // Footer
                let str = "" + pdf.internal.getNumberOfPages();
                pdf.setFontSize(10);
        
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                let pageSize = pdf.internal.pageSize;
                let pageHeight = pageSize.height
                  ? pageSize.height
                  : pageSize.getHeight();
                // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                pdf.text(575, 830, str);
              } });

            pdf.save(`Warehouses ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  }
  /* END EXPORT API */


  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    return <Box>
      <StickyPageHeader>
        <Container>
          
          <Row className="d-flex align-items-center">
            <Col classNmae="xs-12 md-4 lg-4">
            <BreadCrumb />
            <h1 className="page-title">Warehouses</h1>
            </Col>
            <Col classNmae="xs-12 md-4 lg-4">
            <div className='d-flex justify-content-end align-items-center pe-4'>
          {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Warehouse Add") && <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('/warehouse/create')} />}
          <Box ml={1}>
            <label class="dropdown me-3 py-2">
              <div class="dd-button-rr secondary">
              <MenuIcon width="26px" height="auto" />
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul class="dd-menu">
                  {/* <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li> */}
                  <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
              </ul>
            </label>
            </Box>
            </div>
            </Col>
          </Row>
            </Container>
      </StickyPageHeader>
      <Card style={{backgroundColor: 'transparent'}}>
        <CardBody>
          <Row>
            <Col md="12">
            {this.state.pageLoading ?<Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />:
              this.customTable()}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.customDialog()}
    </Box>;
  }
}

export default WareHouse;
