import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from "../../components/Auth/AuthDataProvider";
import apiUtil from '../../api/apiUtil';
import authApi from "../../api/Authentication";
import PageLayout from './PageLayout';
import { RoleAccessService } from '../../data/role-access';
import PageNotFound from '../../views/PageNotFound/PageNotFound';

const RouteLayout = ({ component: Component, menu_name: menu_name, layout: Layout, ...rest }) => {
  const { authenticated,setAuthenticated, refreshToken, token, setToken, setAccessToken, setRefreshToken, setTokenType, currentRole, clearStorage } = useContext(AuthContext)

  const grantedMenu = new RoleAccessService();
  const isShouldAccess = grantedMenu.isPermissionActionAllowed(currentRole, menu_name);
  // const isShouldAccess = true
  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date()) {
        setAuthenticated(false)
        callRefreshTokenApi(refreshToken);
        // clearStorage();
        // return;
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
          setAuthenticated(true)
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }


  React.useEffect(() => {
    document.body.className = "";
    document.body.classList.add(rest.path.replace("/", "").toLowerCase());
    checkToken();
  });


  Layout = Layout || PageLayout;

  // if(authenticated){
  //   console.log(rest, "route layout")
  //   return (
  //     <Route {...rest} render={matchProps => (
  //         <Layout isFluid={rest.isFluid}>
  //           <Component {...matchProps} isFluid={rest.isFluid} />
  //         </Layout>
  //     )} />
  //   )
  // } else {
  //   return (
  //     <Redirect to="/sign-in" />
  //   )
  // }
  if(authenticated && isShouldAccess){
    return (
      <Route {...rest} render={matchProps => (
        <Layout isFluid={rest.isFluid} >
          <Component {...matchProps} isFluid={rest.isFluid}/>
        </Layout>
      )} />
    )
  } else if(authenticated){
    return (
      <Route {...rest} render={matchProps => (
          <Layout isFluid={rest.isFluid} >
            <PageNotFound {...matchProps} isFluid={rest.isFluid} />
          </Layout>
      )} />
    )
  } else {
    return (
      <Redirect to="/sign-in" />
    )
  }
};

export default RouteLayout;