import React, { Component } from 'react';

import { 
    Box,
    Grid,
    Container,
    Divider,
    InputLabel,
    Select,
    Card,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import CustomLabel from '../../components/CustomFields/CustomLabel';
import CustomInput from '../../components/CustomFields/CustomInput';
import IconButton from "../../components/Button/IconButton";
import CustomButton from '../../components/CustomFields/CustomButton';
import { RMIUploader } from "react-multiple-image-uploader";
import queryString from 'query-string';
import dataUrlToBlob from 'dataurl-to-blob'

import apiUtil from "../../api/apiUtil";
import wmsApi from "../../api/wmsApi";
import Api from "../../api/api";
import customerApi from "../../api/Customer";
import moment from 'moment'
import { Row, Col, Form } from 'react-bootstrap';

let dimensions = {
  dimensions: [
    {
      key1 : '',
      key2 : '',
      key3 : '',
      unit : '',
    }
  ]
};    
class InboundEditForm extends Component {
    constructor (porps) {
        super(porps)
        this.state = {
            dataId: '',
            isLoading: false,
            name: '',
            item_type: "Good",
            nameValidated: false,
            sku:'',
            skuValidated: false,
            brand:'',
            weight: 0,
            dimensions: '',
            dimensions1: '',
            dimensions2: '',
            dimensions3: '',
            dimensions4: '',
            // inbound_date: '',
            expire_date: '',
            inbound_dateValidated:false,
            cost_prices: 0,
            selling_prices: 0,
            // warehouse_id:'',
            warehouseValidated: false,
            openingStockValidated: false,
            warehouses:[],
            opening_stock: 0,
            // stock_on_hand: 0,
            img_urls: [],
            status: true,
            image_medium_url: '',
            isImgLoading: false,
            dataSources : [],
            disabled: false,
            progress: null,
            customerList: [],
            batch_id: '',
            batch_idError: '',
            batch_idValidated: false,
            
        }
    }
    
    handleDimension = (val1, val2, val3, unit) => {

        dimensions.dimensions.push({
            key1: val1,
            key2: val2,
            key3: val3,
            unit: unit,
        });
        this.setState({
          dimensions : dimensions.dimensions.slice(-1)[0],
        });
       

      // this.setState({
      //   dimensions : this.state.dimensions1 + ' ' + this.state.dimensions4 + ' x ' + this.state.dimensions2  + ' ' + this.state.dimensions4 + ' x ' + this.state.dimensions3 + ' ' + this.state.dimensions4 ,
      // });
    }

    componentDidMount () {
        
        let urlQuery = queryString.parse(window.location.search);
        this.getCustomer()
        if (urlQuery.id !== undefined) {
          this.setState({
            dataId: urlQuery.id
          });
          this.getWareHouse(this.callDetailsApi(urlQuery.id, (data) => {
              if(data) {
                  let img_urls = []
                  if (data.item_images.length > 0) {
                  data.item_images.map(img => {
                    img_urls.push(img.image_url)
                  })
                  }
                  let dimensions_retrieve = '';
                  if(data.dimensions != '')
                  { 
                    dimensions_retrieve = JSON.parse(data.dimensions)
                  } 
                  this.setState({
                      name: data.name,
                      item_type: data.item_type ? data.item_type: this.state.item_type,
                      sku: data.sku ? data.sku: this.state.sku,
                      brand: data.brand ? data.brand : this.state.brand,
                      weight: data.weight? data.weight : this.state.weight,
                      dimensions: data.dimensions ? dimensions_retrieve : this.state.dimensions,
                      
                      dimensions1: dimensions_retrieve ? dimensions_retrieve.key1 :'',
                      dimensions2: dimensions_retrieve ? dimensions_retrieve.key2 : '',
                      dimensions3: dimensions_retrieve ? dimensions_retrieve.key3 : '',
                      dimensionsUnit: dimensions_retrieve ? dimensions_retrieve.unit : '',
                      inbound_date: (data && data.inbound_date) ? moment(data.inbound_date).format(apiUtil.getDefaultDateFormat()) : '',
                      expire_date: (data && data.expire_date) ? moment(data.expire_date).format(apiUtil.getDefaultDateFormat()) : '',
                      opening_stock: data.opening_stock ? data.opening_stock : this.state.opening_stock,
                      batch_id: data.batch_id,
                      warehouse_id: data.warehouse_id ? data.warehouse_id : this.state.warehouse_id,
                      // customer_id : data.created_by ? data.created_by : this.state.customer_id,
                      img_urls: img_urls
                  })
              }
            }) )
            
          
        } 
    }

    callDetailsApi = (id = '', callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
    
        this.setState({
          isLoading: true
        }, () => {
          wmsApi.read('items/' + id, null, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback((data && data.result) ? data.result : null);
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback(null, []);
                  }
                });
              });
          });
        });
      }
    getWareHouse = (callback = null) => {
        const  accessToken = localStorage.getItem("access_token");

        this.setState({
          isLoading: true
        }, () => {
          let param = {
          };
    
          wmsApi.read('warehouses', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  warehouses: (data.result && data.result.length > 0) ? data.result : [],
                  isLoading: false,
                });
                if (callback) {
                    callback(data)
                }
              }, (error, type) => {
                this.setState({
                  data: [],
                  total: 0,
                  isLoading: false,
                });
              });
          });
        });
    }
    callReadItemList = (batch_id ) => {

      const accessToken = localStorage.getItem("access_token");

      this.setState({
        isItemLoading: true
      }, () => {
        let param = {
          batch_id: batch_id
        };
  
        wmsApi.read('items', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              if(data.result && data.result.length > 0) {
                this.setState({
                  batch_idValidated: true,
                  batch_idError:"Batch Id is already exit",
                  isItemLoading: false,
                });
              } else  {
                this.setState({
                  batch_idValidated: false,
                  batch_idError:"",
                  isItemLoading: false,
                });
              }

            }, (error, type) => {
              this.setState({
                isItemLoading: false,
              });
            });
        });
      });

    }

    getCustomer = async () => {
      await customerApi.get().then(data => {
        if (Array.isArray(data.data)) {
          let customerList = [];
  
          data.data.forEach((val) => {
            customerList.push({
              value: val.id,
              label: val.company_name,
              name: val.company_code
            });
          });
  
          this.setState({
            customerList: customerList
          });
          
          if (this.state.dataId !== "") {
            this.getData(this.state.dataId);
          } else {
            this.setState({
              pageLoading: false,
            });
          }
        } else {
          apiUtil.toast('Failed to get customer data.', 'error');
        }
      }).catch(error => console.log(error));
    }

    
    wareHouseList = () => {
        return <>
        {this.state.warehouses.length > 0 ?
        this.state.warehouses.map((data)=>{
            
           return <option value={data.id} disabled> {data.name}</option>
        }) : "" }
        </>
    }

    importedImglist = () => {
        return <>
        {this.state.img_urls.length > 0 ?
        
        this.state.img_urls.map((data)=>{
            
          return <img src={data} width="100px" height="auto" />
        }) : "" }
        </>
    }
    onUpload = (data) => {
        
        let dataSources = this.state.dataSources
        data.map(d=>{
            dataSources.push({dataURL: d.dataURL, id:dataSources.length + 1 , file: d.file})
        })
        this.setState({dataSources})
    };

    onSelect = (data) => {
        
        let img_urls = []
        let promises= []
        data.map((d, i) => {
            const blob = dataUrlToBlob(d.dataURL)
            let promise = this.callUploadFilesApi(blob, d.img.file.name)
            promises.push(promise)
        })
        Promise.all(promises).then((respone) => {
            if(respone.length > 0 ) {
                respone.map(res => {
                    if(res.result) {
                        img_urls.push(res.result.image_exact_url)
                    }
                })
            }

            this.setState({img_urls})
        })
        
    };

    onRemove = (id) => {
        
        let dataSources = this.state.dataSources
        dataSources.map(data => {
            if(data.id == id) {
                let index = dataSources.indexOf(data);
                if (index !== -1) {
                    dataSources.splice(index, 1);
                }
                return
            }
        })
        this.setState({dataSources})

    };

    mulitImgUploader = () => {
        return <RMIUploader
                    isOpen={true}
                    onSelect={this.onSelect}
                    onUpload={this.onUpload}
                    onRemove={this.onRemove}
                    dataSources={this.state.dataSources}
                />
    }
    
    callCreateApi = (row, callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
    
        this.setState({
          isLoading: true
        }, () => {
          wmsApi.create('items', row, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback();
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  apiUtil.toast(error, 'error');
                });
              });
          });
        });
      }
    
      callUpdateApi = (id, row, callback = null) => {
        const  accessToken = localStorage.getItem("access_token");
    
        this.setState({
          isLoading: true
        }, () => {
          wmsApi.update('items/' + id, row, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback();
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  apiUtil.toast(error, 'error');
                });
              });
          });
        });
      }
      /* END API */


  /* SUBMIT */
  handleSubmit = (e) => {

    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    this.setState({
        nameValidated: false,
        skuValidated: false,
        warehouseValidated: false,
        inbound_dateValidated: false,
        expire_dateValidated: false,
    });
    
    
    if(this.state.name == ''){
      this.setState({
        nameValidated: true
      });
    }
    if(this.state.sku == '' ){
      this.setState({
        skuValidated: true
      });
    }
    if(this.state.warehouse_id == ''){
      this.setState({
        warehouseValidated: true
      });
    }

    if(this.state.opening_stock == ''){
      this.setState({
        openingStockValidated: true
      });
    }

    if(this.state.inbound_date == ''){
      this.setState({
        inbound_dateValidated: true
      });
    }
    if(this.state.expire_date == ''){
      this.setState({
        expire_dateValidated: true
      });
    }
    let validated_batch = true
    if(this.state.batch_idValidated) {
      validated_batch = false
    }

    if (form.checkValidity() === true && validated_batch ) { 
      let data = {
        name: this.state.name,
        type: this.state.item_type,
        sku: this.state.sku,
        brand: this.state.brand,
        weight: this.state.weight,
        dimensions: JSON.stringify(this.state.dimensions),
        inbound_date: this.state.inbound_date,
        expire_date: this.state.expire_date,
        // cost_prices: this.state.cost_prices,
        // selling_prices: this.state.selling_prices,
        warehouse_id: this.state.warehouse_id,
        opening_stock: this.state.opening_stock,
        img_urls: this.state.img_urls,
        batch_id: this.state.batch_id
    }

    if(this.state.dataId && this.state.dataId !== ""){

      this.setState({
        isLoading: true,
      });

        this.callUpdateApi(this.state.dataId, data, () => {
          this.setState({
            isLoading: false
          }, () => {
            this.props.history.push({
              pathname: '/inbound-orders',
            });
            apiUtil.toast('Successfully Updated', 'success');
          });
        });

      } else {

        this.setState({
          isLoading: true,
        });

          this.callCreateApi(data, () => {
            this.setState({
              isLoading: false
            }, () => {
              this.props.history.push({
                pathname: '/items/all-items',
              });
              apiUtil.toast('Successfully Created', 'success');
            });
          });
      }

    }

        
  }


    itemDetailsForm = () => {
        return <Box className={'job-details-step'} >
        <Grid container>
            <Grid item xs={12} className="p-4">
                <CustomLabel
                    label={'Item Details'}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={5}>
                            <CustomInput
                                label={'Item Name'}
                                placeholder={'Enter Iten Name here...'}
                                value={this.state.name}
                                error={this.state.nameValidated}
                                required={true}
                                onChange={(e) => {
                                    this.setState({
                                        name: e.target.value,
                                        nameValidated: (e.target.value !== '') ? false : true,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={1}>
                        <CustomLabel
                            label={'Select Item Type'}
                        />
                        </Box>
                        <RadioGroup aria-label="speed" name="speed" style={{display: 'block'}} value={this.state.item_type} 
                        onChange={ (e) => {
                            this.setState({
                                item_type: e.target.value,
                            });
                        }}
                        >
                            <FormControlLabel style={{ display: 'inline-block',  width: "99px" }}  value="Goods" control={<Radio />} label="Goods" />
                            <FormControlLabel style={{ display: 'inline-block',  width: "99px"}}  value="Services" control={<Radio />} label="Services" />
                        
                        </RadioGroup>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'SKU'}
                                placeholder={'Enter SKU here...'}
                                value={this.state.sku}
                                error={this.state.skuValidated}
                                required={true}
                                onChange={(e) => {
                                    this.setState({
                                        sku: e.target.value,
                                        skuValidated: (e.target.value !== '') ? false : true,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Batch ID'}
                                placeholder={'Enter Batch ID here...'}
                                error={this.state.batch_idValidated}
                                errorText={this.state.batch_idError}
                                value={this.state.batch_id}
                                onChange={(e) => {
                                    if(e.target.value) {
                                      this.setState({
                                        batch_id: e.target.value,
                                      }, () => {
                                        this.callReadItemList(e.target.value)
                                      });
                                    }
                                    
                                    
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                {/* <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <Box>
                                <InputLabel >Customer</InputLabel>
                                <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px", backgroundColor: 'transparent'}}
                                  placeholder={'Select Customer here...'}
                                  value={this.state.customer_id}
                                  native
                                  required={true}
                                  disableUnderline
                                  error={this.state.customerValidated}
                                  onChange={(e) => {
                                    this.setState({
                                      customer_id: e.target.value,
                                        customerValidated: (e.target.value !== '') ? false : true,
                                    });
                                }}
                                >
                                  <option value=""> Select Customer </option>
                                  {this.state.customerList.length > 0 &&
                                  this.state.customerList.map((data)=>{
                                      
                                    return <option value={data.value}> {data.label}</option>
                                  })}
                                  
                                </Select>
                                </Box>
                        </Box>
                    </Container>
                </Grid> */}
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Weight'}
                                placeholder={'Enter Weight in Kg here...'}
                                value={this.state.weight}
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                        weight: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                    <label className="mb-2">Dimensions <span className='text-muted' style={{fontSize:'10px'}}>(Length X Width X Height)</span></label>
                        <Row className="m-0 p-0">
                          <Col xs={12} className="d-flex justify-content-evenly align-items-center w-100 border m-0 p-0 rounded" >
                          <div>
                          <input
                              value={this.state.dimensions1}
                              className='custom-input-dimension'
                              type={'number'}
                              onChange={(e) => {
                                  this.setState({
                                      dimensions1: e.target.value,
                                  }, () => {
                                    this.handleDimension(this.state.dimensions1, this.state.dimensions2, this.state.dimensions3, this.state.dimensionsUnit);
                                  });
                                  
                              }}
                          />  
                          </div>
                          <div className="mx-2"> X </div>
                          <div>
                           <input
                                value={this.state.dimensions2}
                                type={'number'}
                                className='custom-input-dimension '
                                onChange={(e) => {
                                    this.setState({
                                        dimensions2: e.target.value,
                                    }, () => {
                                      this.handleDimension(this.state.dimensions1, this.state.dimensions2, this.state.dimensions3, this.state.dimensionsUnit);
                                    });
                                }}
                            /> 
                            </div>
                             <div className="mx-2"> X </div>
                             <div>
                             <input
                                value={this.state.dimensions3}
                                className='custom-input-dimension'
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                        dimensions3: e.target.value,
                                    }, () => {
                                      this.handleDimension(this.state.dimensions1, this.state.dimensions2, this.state.dimensions3, this.state.dimensionsUnit);
                                    });
                                }}
                            />  
                            </div>
                             <Form.Select aria-label="Default select example" 
                            style={{height: '40px'}}
                            onChange={(e) => {
                              this.setState({
                                dimensionsUnit: e.target.value,
                              }, () => {
                                this.handleDimension(this.state.dimensions1, this.state.dimensions2, this.state.dimensions3, this.state.dimensionsUnit);
                              });
                            }}
                              >
                              <option value={this.state.dimensionsUnit}>Unit</option>
                              <option value="cm" selected={this.state.dimensionsUnit == 'cm' ? true : false}>cm</option>
                              <option value="in" selected={this.state.dimensionsUnit == 'in' ? true : false}>in</option>
                            </Form.Select>
                          </Col>
                        </Row>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                        <CustomInput
                            readOnly={this.state.dataId == '' ? false: true}
                            label={'Inbound Date'}
                            placeholder={'DD/MM/YYYY'}
                            color={'secondary'}
                            type={"date"}
                            endAdornment={(obj) => {
                                return <Box
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <CalendarIcon />
                                </Box>
                            }}
                            value={this.state.inbound_date}
                            error={this.state.inbound_dateValidated}
                            required={true}
                            onChange={(e) => {
                                this.setState({
                                    inbound_date: e.target.value,
                                    inbound_dateValidated: (e.target.value !== '') ? false : true,
                                });
                            }}
                        />
                        </Box>
                    </Container>
                </Grid>
                
                {/* <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Cost Price'}
                                placeholder={'Enter Cost Price here...'}
                                value={this.state.cost_prices}
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                        cost_prices: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Selling Price'}
                                placeholder={'Enter Selling Price here...'}
                                value={this.state.selling_prices}
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                        selling_prices: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid> */}
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <Box>
                                <InputLabel >Warehouse</InputLabel>
                                <Select className="custom-input-component" id="custom-input-style" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px"}}
                                  placeholder={'Enter warehouse here...'}
                                  value={this.state.warehouse_id}
                                  native
                                  // required={true}
                                  disabled = "disabled"
                                  disableUnderline
                                  error={this.state.warehouseValidated}
                                  onChange={(e) => {
                                    this.setState({
                                        warehouse_id: e.target.value,
                                        warehouseValidated: (e.target.value !== '') ? false : true,
                                    });
                                }}
                                >
                                  <option value="" disabled> Select Warehouse </option>
                                  {this.wareHouseList()}
                                </Select>

                                </Box>
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                        <CustomInput
                            // readOnly={this.state.dataId == '' ? false: true}
                            label={'Expire Date'}
                            placeholder={'DD/MM/YYYY'}
                            color={'secondary'}
                            type={"date"}
                            error={this.state.expire_dateValidated}
                            required={true}
                            endAdornment={(obj) => {
                                return <Box
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <CalendarIcon />
                                </Box>
                            }}
                            value={this.state.expire_date}
                            onChange={(e) => {
                                this.setState({
                                    expire_date: e.target.value,
                                    expire_dateValidated: (e.target.value !== '') ? false : true,
                                });
                            }}
                        />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Opening Stock'}
                                placeholder={'Enter Opening Stock here...'}
                                value={this.state.opening_stock}
                                error={this.state.openingStockValidated}
                                required={true}
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                        opening_stock: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                        {this.mulitImgUploader()}
                        </Box>
                    </Container>
                </Grid> 
                {this.state.img_urls.length > 0 && <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box pt={2}>
                            <CustomLabel
                        label={'Imported Image'}
                        />
                        </Box>
                    
                        <Box clone pt={2}>
                        
                        {this.importedImglist()}
                        </Box>
                    </Container>
                </Grid>}
                {/* <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2}>
                            <CustomInput
                                label={'Stock On Hand'}
                                placeholder={'Enter Stock on Hand in Cm here...'}
                                value={this.state.stock_on_hand}
                                type={'number'}
                                onChange={(e) => {
                                    this.setState({
                                        stock_on_hand: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                    </Container>
                </Grid> */}
                <Grid item xs={12}>
                    <Container maxWidth={'xs'}>
                        <Box clone pt={2} mb={5}>
                        <CustomButton 
                        type={'submit'}
                        color={'secondary'}
                        isLoading={this.state.isImgLoading}
                        ><Box pl={1} pr={1}>Submit</Box>
                        </CustomButton>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
        </Box>
    }
    render () {
        return <Box className="dashboard-page">
        <StickyPageHeader>
          <Container>
            <BreadCrumb />
            <h1 className="page-title">{this.state.dataId !== "" ? "Update Item" : "Create Item"}</h1>
          </Container>
        </StickyPageHeader>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Container maxWidth="lg" >
                <Box mt={5}>
                    {
                  (!this.state.isLoading)
                  ?
                  <Card mt={5}>
                    {this.itemDetailsForm()}
                  </Card>
                  :
                  <Card>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
                </Box>
                
            </Container>

        </form>
        </Box>
    }
}
export default InboundEditForm;