import React, { Component } from 'react';

import { 
  Box,
} from '@material-ui/core';

class StickyPageHeader extends Component {
  render() {
    return <Box className="custom-sticky-container">
      <Box className={'custom-sticky-page-header'}>
        {this.props.children}
    </Box>
    </Box>
  }
}

export default StickyPageHeader;
