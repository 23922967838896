import React, { Component } from 'react';
import { AuthContext } from "../components/Auth/AuthDataProvider";
import { NavLink } from 'react-router-dom';

import { 
  Grid,
  IconButton,
} from '@material-ui/core';
import apiUtil from "../../src/api/apiUtil";
import authApi from "../../src/api/api";
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';

import ProfileDropdown from "../components/Header/ProfileDropdown";

const useStyles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
});

class Header extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let showHeader = ((props.showHeader === true || props.showHeader === false) ? props.showHeader : true);

    this.state = {
      data :  [],
      isLoadingStatus: false,
      appcom_data:[],
      showHeader: showHeader,
      isLoggedUser: false,
      isDrawerOpen: false,
      profileMenu: null,
    };
  }

  componentDidMount() {
    this.checkIsUserLoggedIn();
    this.callReadApi();

  }

  checkIsUserLoggedIn = () => {
    const { authenticated } = this.context;
    this.setState({ 
      isLoggedUser: authenticated
    });
  }

  logout = ()  => {
    const { clearStorage } = this.context;

    this.setState({
      profileMenu: null,
      isDrawerOpen: false
    });

    clearStorage();

    window.location = "/";
  }

  callReadApi = ( callback = null) => {
    const { accessToken } = this.context;
    let param = {
        is_paid: false,
    }

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_subscription_billing' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            
            this.setState({
              isLoading: false,
              data:data.result,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  alertShow = () => {
    let billDate = this.state.data[0] ? this.state.data[0].billing_date : new Date();
    let suspendedDate = new Date(billDate)
    let currnetDate = new Date()
    let appcom_status_id = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_status_id : null;
    let appcom_is_trial  = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_license.is_trial : false;
    let date_subscription_end = this.state.appcom_data[0] ? new Date(this.state.appcom_data[0].application_company_license.date_subscription_end) :new Date();
    suspendedDate.setDate(suspendedDate.getDate() + 14);
    if(suspendedDate < currnetDate ) {
     let timeDiff = Math.abs(suspendedDate.getTime() - currnetDate.getTime());
     let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <div style={{textAlign:'center', backgroundColor:'#f8d7da', padding:'10px'}}>
          Your Evfy Tracker services are suspended. 
          <NavLink to={{pathname: '/invoice'}} > Please pay </NavLink> within {total_days} days to avoid account removal.</div>
    } else if( appcom_status_id == null && appcom_is_trial) {
     let timeDiff = Math.abs(date_subscription_end.getTime() - currnetDate.getTime());
     let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <div style={{textAlign:'center', color: '#fff', paddingLeft:'25px'}}>
          You have {total_days} days left in your trial. Subscribe to evfy.sg by
          <span 
          style={{cursor:"pointer"}}
          onClick={()=>{
            this.setState({
              openDialog:true,
            })
           }} 
          
          ><u> setting your device quota</u></span> now.</div>
    }
    return <div></div>
}

  getHeader = () => {
    return <Grid container alignItems={'center'} style={{zIndex: 4090}}>
      <Grid item xs={6} lg={'auto'}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => {
            if(this.props.onDrawerToggle){
              this.props.onDrawerToggle();
            }
          }}
          className={this.props.classes.menuButton}
        >
          <MenuIcon style={{
            color: 'white'
          }} />
        </IconButton>
      </Grid>
      <Grid item xs={true}>
        
      </Grid>
      <Grid item xs={'auto'}>
        {this.alertShow()}
      </Grid>
      <Grid item xs={'auto'}>
        <ProfileDropdown 
          profileMenu={this.state.profileMenu}
          onLogout={this.logout}
        />
      </Grid>
    </Grid>
  }
  
  render() {
    if(this.state.showHeader){
      return this.getHeader()
    }
  }
}

export default withStyles(useStyles)(Header);
