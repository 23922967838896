import React, { Component } from 'react';

import { 
    Box,
    Input
} from '@material-ui/core';

import CustomLabel from './CustomLabel';

class CustomInput extends Component {
  
	constructor(props){
		super(props);

        this.refInput = React.createRef();

        let label = props.label ? props.label : "";
        let color = props.color ? props.color : "default";
        let labelComponent = props.labelComponent ? props.labelComponent : null;
        let name = props.name ? props.name : "";
        let value = props.value ? props.value : "";
        let min = props.min ? props.min : "";
        let max = props.max ? props.max : "";
        let type = props.type ? props.type : "text";
        let width = props.width ? props.width : "100%";
        let autoComplete = props.autoComplete ? props.autoComplete : "";
        let placeholder = props.placeholder ? props.placeholder : "";
        let className = props.className ? props.className : "";
        let helperText = props.helperText ? props.helperText : "";
        let errorText = props.errorText ? props.errorText : "";
        let rows = props.rows ? props.rows : 1;
        let rowsMax = props.rowsMax ? props.rowsMax : null;
        let autoFocus = ((this.props.autoFocus === true || this.props.autoFocus === false) ? this.props.autoFocus : false);
        let disabled = ((this.props.disabled === true || this.props.disabled === false) ? this.props.disabled : false);
        let readOnly = ((this.props.readOnly === true || this.props.readOnly === false) ? this.props.readOnly : false);
        let disableUnderline = ((this.props.disableUnderline === true || this.props.disableUnderline === false) ? this.props.disableUnderline : true);
        let error = ((this.props.error === true || this.props.error === false) ? this.props.error : false);
        let fullWidth = ((this.props.fullWidth === true || this.props.fullWidth === false) ? this.props.fullWidth : true);
        let multiline = ((this.props.multiline === true || this.props.multiline === false) ? this.props.multiline : false);
        let required = ((this.props.required === true || this.props.required === false) ? this.props.required : false);
        let startAdornment = props.startAdornment ? props.startAdornment : null;
        let endAdornment = props.endAdornment ? props.endAdornment : null;

		this.state = {
            label: label,
            color: color,
            labelComponent: labelComponent,
            className: className,

            name: name,
            value: value,
            placeholder: placeholder,
            type: type,

            min: min,
            max: max,

            disabled: disabled,
            readOnly: readOnly,
            required: required,

            autoComplete: autoComplete,
            autoFocus: autoFocus,
            disableUnderline: disableUnderline,
            multiline: multiline,
            rows: rows,
            rowsMax: rowsMax,
            fullWidth: fullWidth,
            width: width,

            startAdornment: startAdornment,
            endAdornment: endAdornment,

            error: error,
            errorText: errorText,
            helperText: helperText,
		}
	}
    

    componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value ? nextProps.value : ""
			});
        }
        if (nextProps.type !== this.state.type) {
			this.setState({
				type: nextProps.type
			});
        }
        if (nextProps.placeholder !== this.state.placeholder) {
			this.setState({
				placeholder: nextProps.placeholder
			});
        }
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
        }
        if (nextProps.readOnly !== this.state.readOnly) {
			this.setState({
				readOnly: nextProps.readOnly
			});
        }
        if (nextProps.required !== this.state.required) {
			this.setState({
				required: nextProps.required
			});
        }
        if (nextProps.error !== this.state.error) {
			this.setState({
				error: nextProps.error
			});
		}
        if (nextProps.endAdornment !== this.state.endAdornment) {
			this.setState({
				endAdornment: nextProps.endAdornment
			});
		}
        if (nextProps.startAdornment !== this.state.startAdornment) {
			this.setState({
				startAdornment: nextProps.startAdornment
			});
		}
        if (nextProps.helperText !== this.state.helperText) {
			this.setState({
				helperText: nextProps.helperText
			});
		}
        if (nextProps.errorText !== this.state.errorText) {
			this.setState({
				errorText: nextProps.errorText
			});
		}
        if (nextProps.rowsMax !== this.state.rowsMax) {
			this.setState({
				rowsMax: nextProps.rowsMax
			});
		}
    }

    focus = () => {
        this.refInput.current.focus();
    };

    resize = (event) => {
        event.target.scrollTop = event.target.scrollHeight;
    };

	render() {
		return <Box className={"custom-input-component " + this.state.className + ' ' + this.state.color}>
            {this.state.label && <CustomLabel 
                className={"custom-label"}
                label={this.state.label}
                labelComponent={this.state.labelComponent}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
            <Input 
                className={"custom-input"}
                value={this.state.value}
                id={(this.state.name !== '') ? this.state.name + '-id' : new Date().getTime() + '-id'}
                name={this.state.name}
                type={this.state.type}
                autoComplete={this.state.autoComplete}
                autoFocus={this.state.autoFocus}
                disabled={this.state.disabled}
                readOnly={this.state.readOnly}
                min={this.state.min}
                max={this.state.max}
                disableUnderline={this.state.disableUnderline}
                startAdornment={(typeof this.state.startAdornment === 'function') ? this.state.startAdornment(this) : this.state.startAdornment}
                endAdornment={(typeof this.state.endAdornment === 'function') ? this.state.endAdornment(this) : this.state.endAdornment}
                error={this.state.error}
                fullWidth={this.state.fullWidth}
                multiline={this.state.multiline}
                rows={this.state.rows}
                rowsMax={this.state.rowsMax}
                placeholder={this.state.placeholder}
                required={this.state.required}
                inputProps={this.props.inputProps}
                ref={this.refInput}
                onMouseUp={this.props.onMouseUp}
                onMouseDown={this.props.onMouseDown}
                onClick={(e) => {
                    e.stopPropagation();

                    if(this.props.onClick)
                        this.props.onClick(e);
                }}
                onInput={(e) => {
                    e.stopPropagation();

                    if(this.props.onInput)
                        this.props.onInput(e);
                }}
                onChange={(event) => {
                    this.resize(event);

                    this.setState({
                        value: event.target.value
                    });
                    
                    if(this.props.onChange)
                        this.props.onChange(event);
                }}
                onBlur={(e) => {
                    if(this.props.onBlur) {
                        this.props.onBlur(e);
                    }
                }}
                
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        if(this.props.onPressEnter){
                            this.props.onPressEnter(event);
                        }
                    }
                }}
            />
            {(this.state.error && this.state.errorText) && <CustomLabel 
                className={"custom-label text-danger"}
                size={'sm'}
                label={this.state.errorText}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
            {this.state.helperText && <CustomLabel 
                className={"custom-label " + (this.state.error ? 'text-danger' : '')}
                size={'sm'}
                label={this.state.helperText}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
		</Box>;
	}
}

export default CustomInput;
