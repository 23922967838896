import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const breadcrumbNameMap = {
  '/dashboard': 'Dashboard',
  '/password': 'Change Password',

  '/order': 'Orders',
  '/order/inbound-orders': 'Inbound Orders',
  '/order/inbound-create': 'Create Inbound Orders',
  '/order/inbound-edit': 'Edit Inbound Orders',
  '/order/inbound-order-detail': 'Inbound Order Details',

  '/warehouses': 'Warehouse',
  '/warehouse': 'Warehouse',
  '/warehouse/create':'Add Warehouse',
  '/warehouse/edit':'Edit Warehouse',
  '/warehouse/warehouse-detail':'Warehouse Details',

  '/items': 'Items',
  '/items/all-items': 'All Items',
  '/items/import-items': 'Import Items',
  '/items/item-movements': 'Movement Log',
  '/items/create': "Create Item",
  '/items/edit': "Edit Item",
  '/items/item-detail': "Item Detail",
  '/items/movement-form': "Movement Form",
  
  '/sale-return/all': "Sales Return",
  '/sale-return/details': "Sales Return Details",

  '/customers': 'Customers',
  '/customers/all-customers': 'All Customers',
  '/customers/create-customer': 'Create Customer',
  '/customers/edit-customer': 'Edit Customer',

  // '/transaction': 'Transactions',

  // '/transaction/sale-return': 'Sales Return',
  // '/transaction/sale-return/all': 'All Sales Return',
  // '/transaction/sale-return/details': 'Sales Return Details',


  '/sales-orders': 'Outbound Order',
  '/sales-order': 'Outbound Order',
  '/sales-order/create': 'Create Outbound Order',
  '/sales-order/import-sale-orders': 'Import Outbound Orders',
  '/sales-order/edit': 'Edit Outbound Order',

  '/sale-return': 'Outbound Return',
  '/sale-return/all': 'Outbound Return',


  '/inbound-orders': 'Inbound Order',
  '/inbound-order': 'Inbound Order',
  '/inbound-order/all': 'All Inbound Order',
  '/inbound-order/create': 'Create Outbound Order',
  '/inbound-order/edit': 'Edit Outbound Order',
  '/sales-order/import-sale-orders': 'Import Outbound Orders',

  // '/sale-return/all': 'Sales Return',
  // '/transaction/sales-order/edit': 'Edit Inbound Order',

  // '/transaction/delivery-order': 'Delivery Order',
  // '/transaction/delivery-order/all': 'All Delivery Order',
  // '/transaction/delivery-order/create': 'Create Delivery Order',
  // '/transaction/delivery-order/edit': 'Edit Delivery Order',
  // '/transaction/delivery-order/check': 'Order Check Out',

  // '/transaction/self-collection': 'Self Collection',
  // '/transaction/self-collection/all': 'All Self Collection',
  // '/transaction/self-collection/create': 'Create Self Collection',
  // '/transaction/self-collection/edit': 'Edit Self Collection',

  '/items/balance-enquiry': 'Enquiry',
  '/items/balance-enquiry/list': 'Balance Enquiry',
  '/items/balance-enquiry/all': 'Balance Enquiry All',

  '/reports': 'Reports',
  '/report': 'Reports',
  '/report/in-out': 'Rental Report',
  '/report/monthly-summary': 'Monthly Report',
  '/report/aging': 'Aging Report',
  '/report/sales': 'Item Movement Reports',
  '/report/inventory': 'Inventory Reports',
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BreadcrumbContainer = () => {
  return <div className="breadcrumb-container">
    <Route>
    {({ location }) => {
      const pathnames = location.pathname.split('/').filter((x) => x);
      return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <LinkRouter color="inherit" to="/">
          Home
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <Typography color="textPrimary" key={to}>
                {breadcrumbNameMap[to]}
              </Typography>
              ) : (
              <LinkRouter color="inherit" 
              to={to} 
              key={to}>
                {breadcrumbNameMap[to]}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      );
    }}
    </Route>
  </div>;
};


export default BreadcrumbContainer;
